import React, { Component } from 'react';
import AppContext from '../app-context';
import DevicesCatagory from './DevicesCatagory';
import { Redirect } from 'react-router-dom';
import text from '../../assets/translation.json';
import cc from 'classcat';

import './AddDevices.css';
import PopUp from '../../components/modal/AuthModal/modal';

class AddDevices extends Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {
            smartcar_success: false,
            manufacturers: [],
            ev: [],
            cs: [],
            hvac: [],
            si: [],
        };
    }

    async componentDidMount() {
        this.setState({
            manufacturers: this.context.enabledManufacturers,
            ev: this.context.enabledManufacturers.filter(x => x.type === 'ev'),
            cs: this.context.enabledManufacturers.filter(x => x.type === 'cs'),
            hvac: this.context.enabledManufacturers.filter(x => x.type === 'hvac'),
            si: this.context.enabledManufacturers.filter(x => x.type === 'si'),
        });
    }

    renderButton = (text, condition) => {
        return (
            <button
                onClick={this.context.catagoryHandler}
                className={cc(['AddDevices__Category', { 'AddDevices__catagoryActive bold-text': condition }])}
            >
                {text}
            </button>
        );
    };

    render() {
        if (this.state.smartcar_success) {
            return <Redirect to="/mydevices" />;
        }

        return (
            <div className="AddDevices">
                <PopUp type={this.context.modalType} show={this.context.modalShow} onHide={this.context.hideModal} />
                <div className="AddDevices__container">
                    <h3 className="bold-text" style={{ color: this.context?.theme?.title_color || '#000000' }}>
                        {text[this.context.language].myDevices.addDev}
                    </h3>
                    <section className="AddDevices__Wrapper">
                        <div className="AddDevices__Categories">
                            {this.renderButton(text[this.context.language].addNewDevices.all, this.context.all)}
                            {this.state.ev.length !== 0 &&
                                this.renderButton(text[this.context.language].addNewDevices.ev, this.context.electricVehicles)}
                            {this.state.cs.length !== 0 &&
                                this.renderButton(text[this.context.language].addNewDevices.cs, this.context.chargingStations)}
                            {this.state.hvac.length !== 0 &&
                                this.renderButton(text[this.context.language].addNewDevices.heating, this.context.heating)}
                            {this.state.si.length !== 0 && this.renderButton(text[this.context.language].addNewDevices.si, this.context.si)}
                        </div>
                        <div className="Devices__row">
                            {this.state.manufacturers.map(vehicle => {
                                if ((vehicle.state === 'development' || vehicle.state === 'alpha') && process.env.REACT_APP_ENV === 'production') {
                                    return;
                                }

                                if (this.context.all) {
                                    return (
                                        <DevicesCatagory key={vehicle.id} vehicle={vehicle} onClick={() => this.context.addDevicesHandler(vehicle)} />
                                    );
                                }

                                switch (vehicle.type) {
                                    case 'ev':
                                        return (
                                            this.context.electricVehicles && (
                                                <DevicesCatagory
                                                    key={vehicle.id}
                                                    vehicle={vehicle}
                                                    onClick={() => this.context.addDevicesHandler(vehicle)}
                                                />
                                            )
                                        );
                                    case 'cs':
                                        return (
                                            this.context.chargingStations && (
                                                <DevicesCatagory
                                                    key={vehicle.id}
                                                    vehicle={vehicle}
                                                    onClick={() => this.context.addDevicesHandler(vehicle)}
                                                />
                                            )
                                        );
                                    case 'hvac':
                                        return (
                                            this.context.heating && (
                                                <DevicesCatagory
                                                    key={vehicle.id}
                                                    vehicle={vehicle}
                                                    onClick={() => this.context.addDevicesHandler(vehicle)}
                                                />
                                            )
                                        );
                                    case 'si':
                                        return (
                                            this.context.si && (
                                                <DevicesCatagory
                                                    key={vehicle.id}
                                                    vehicle={vehicle}
                                                    onClick={() => this.context.addDevicesHandler(vehicle)}
                                                />
                                            )
                                        );
                                    default:
                                }
                            })}
                        </div>
                    </section>
                </div>
            </div>
        );
    }
}

export default AddDevices;

import React, { Component } from 'react';

class Failure extends Component {
    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-sm-4"></div>
                    <div className="col-sm-5">
                        <div className="pt-5">
                            <h4 className="text-center">
                                <div className="alert alert-danger" role="alert">
                                    Misslyckades med att lägga till din enhet. <br />
                                    Försök igen om en stund.
                                </div>
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Failure;

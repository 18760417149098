import React from 'react';
import 'reactjs-popup/dist/index.css';
import { Chart } from 'react-google-charts';
import cc from 'classcat';

import AppContext from '../../app-context';
import DeviceBase from './DeviceBase';
import API from '../../../api';
import './Ev.css';
import text from '../../../assets/translation.json';
import InfoTooltip from '../../../components/Common/InfoTooltip';
import Battery from '../../../components/Common/Battery';
import { ChargeScheduleMessage } from './ChargeScheduleMessage';
import { debounce } from 'lodash';

class Ev extends DeviceBase {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        const measurements = this.props.device.measurements || {};
        const spo = this.props.device.spo;
        const countAwayCharge = spo.insights?.count_away_charge;
        const countHomeCharge = spo.insights?.count_home_charge;

        this.state = {
            ...this.state,
            percentage: (100 * (measurements.state_of_charge || 0)).toFixed(0),
            charging: '',
            evPercentage: spo.settings.safety_soc ?? '10',
            evTime: spo.settings.charged_by_hour ?? '5',
            viewport: {
                width: '',
                height: '',
            },
            chargeSessionCount: countAwayCharge + countHomeCharge,
            ChargingLocationPieChartData: [
                ['Type', 'Value'],
                ['Others', countAwayCharge],
                ['Home', countHomeCharge],
            ],
            activateChargingAt: spo.settings.spo_start_hour || 'null',
            range: 0,
        };
    }

    componentDidMount() {
        let measurements = this.props.device.measurements || {};
        if (measurements.charging === true) {
            this.setState({ charging: text[this.context.language].myDevices.charging });
        } else if (measurements.charging === false) {
            if (measurements.plugged === true) {
                this.setState({ charging: text[this.context.language].myDevices.charging_paused });
            } else {
                this.setState({ charging: text[this.context.language].myDevices.not_plugged });
            }
        } else {
            this.setState({ charging: text[this.context.language].myDevices.no_charging });
        }

        this.setState({ range: measurements?.range?.toFixed(0) || text[this.context.language].savings.noData });
        super.componentDidMount();
        this._resize_mixin_callback();
        window.addEventListener('resize', this._resize_mixin_callback);
        this.api = new API(this.context.jwt_token);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this._resize_mixin_callback);
    }

    _resize_mixin_callback = () => {
        this.setState({
            viewport: {
                width: document.documentElement.clientWidth,
                height: document.documentElement.clientHeight,
            },
        });
    };

    evPercentageHandler = e => {
        this.setState({ evPercentage: e.target.value });
        this.debouncedPercentageHandler(e);
    };

    debouncedPercentageHandler = debounce(async e => {
        await this.api.set_device_settings(this.state.device.id, { safety_soc: parseInt(e.target.value, 10) });
    }, 1000);

    mock_ev = () => {
        let link = `/dummy-device-authorize?code=${this.context.jwt_token}&response_type=code&token=ev_token&redirect_uri=http://localhost:3001/callback`;
        window.open(link, '_self');
    };

    activateChargingHandler = async (e, device_id) => {
        this.setState({ activateChargingAt: e.target.value });
        if (e.target.value !== null) {
            await this.api.set_device_settings(device_id, { spo_start_hour: parseInt(e.target.value, 10) });
        } else {
            await this.api.set_device_settings(device_id, { spo_start_hour: null });
        }
    };

    finishChargingHandler = async (e, device_id) => {
        this.setState({ evTime: e.target.value });
        await this.api.set_device_settings(device_id, { charged_by_hour: parseInt(e.target.value, 10) });
    };

    render() {
        const deviceState = this.inferState();
        const { lostStatus } = this.props;

        let tooltip =
            deviceState === 'unsupported' && this.state.device.spo.unsupported_reason !== null
                ? this.state.device.spo.unsupported_reason
                : this.props.tooltipMsgs[deviceState];

        let chargeSessionChart;
        if (this.state.chargeSessionCount) {
            chargeSessionChart = (
                <>
                    <div className="Ev__pieChartWrapper">
                        <Chart
                            chartType="PieChart"
                            data={this.state.ChargingLocationPieChartData}
                            options={{
                                width: '100%',
                                height: '100px',
                                chartArea: {
                                    height: `53%`,
                                    width: '23%',
                                },
                                pieHole: 0.7,
                                is3D: false,
                                slices: [
                                    { color: this.context.theme?.pie_neutral_color || '#d4cfc9' },
                                    { color: this.context.theme?.pie_highlight_color || '#ee801c' },
                                ],
                                legend: {
                                    position: 'none',
                                },
                                pieSliceText: 'none',
                                pieStartAngle: 280,
                                backgroundColor: {
                                    fill: 'none',
                                },
                            }}
                        />
                    </div>
                    <div className="Ev__pieChartLegends">
                        <div>
                            <span
                                className="Ev__pieChartLegendHomeBullet"
                                style={{ background: this.context.theme?.pie_highlight_color || '#ee801c' }}
                            >
                                &nbsp;
                            </span>
                            <span className="Ev__pieChartLegendsText">{text[this.context.language].myDevices.home}</span>
                        </div>
                        <div>
                            <span
                                className="Ev__pieChartLegendOtherBullet"
                                style={{ background: this.context.theme?.pie_neutral_color || '#d4cfc9' }}
                            >
                                &nbsp;
                            </span>
                            <span className="Ev__pieChartLegendsText">{text[this.context.language].myDevices.other}</span>
                        </div>
                    </div>
                </>
            );
        } else {
            chargeSessionChart = <p className="genericDescription">No charge sessions</p>;
        }

        return (
            <div className="Ev">
                {(deviceState === 'pending' || deviceState === 'lost' || deviceState === 'unsupported') && (
                    <div className={cc(['badge-indicator', deviceState])}>
                        <span className="dot"></span>
                        {text[this.context.language].myDevices[deviceState]}
                        <InfoTooltip tooltip={tooltip} type={`deviceStatus-${deviceState}`} />
                    </div>
                )}

                <div
                    className="Ev__crossCard"
                    onClick={() => {
                        this.props.openDeleteModal(this.state.device);
                    }}
                >
                    &#xf00d;
                </div>

                <div className={cc(['Ev__firstRow', { [`device-state-lost`]: lostStatus }])}>
                    <div className="Ev__title bold-text">
                        {this.props.productName(this.state.device)}
                        <div
                            className="edit-icon"
                            onClick={() => {
                                this.props.openEditModal(this.state.device);
                            }}
                        >
                            &#x1F58A;
                        </div>
                    </div>
                    <div className="ev__data-wrapper">
                        {!lostStatus && (
                            <div className="details">
                                <div className="detail-text">
                                    <label className="range">
                                        {this.props.device.measurements?.range
                                            ? this.state.range.toLocaleString('se-SE').replace(',', ' ') + ' km'
                                            : this.state.range}
                                    </label>
                                    <label className="sub-detail">{text[this.context.language].myDevices.range}</label>
                                </div>
                                <div className="detail-text">{this.renderSmartChargingSetting()}</div>
                            </div>
                        )}

                        {!lostStatus ? (
                            <div>
                                <div className="Ev__ProgressBarContainer">
                                    <div className="Ev__ProgressBar">
                                        <Battery
                                            className="circle-container devices__circle-container"
                                            charge={this.state.percentage}
                                            emptyColor={this.context.theme.pie_neutral_color ? this.context.theme.pie_neutral_color : '#e6e6e6'}
                                            fillColor={this.context.theme.pie_highlight_color ? this.context.theme.pie_highlight_color : '#ee801c'}
                                        />
                                        <p className="ev__percent">{this.state.percentage}%</p>
                                    </div>
                                    <label className="Ev__ChargingStatus">{this.state.charging}</label>
                                </div>
                            </div>
                        ) : (
                            <div className="restore-btn">
                                <button
                                    className="btn"
                                    style={{
                                        backgroundColor: this.context?.theme?.button_color || '#aeaeae',
                                        color: this.context?.theme?.button_text_color || '#000000',
                                    }}
                                    onClick={() => this.context.restoreAccess(this.state.device)}
                                >
                                    {text[this.context.language].myDevices.restore_access}
                                </button>
                            </div>
                        )}
                    </div>
                </div>

                {!lostStatus && (
                    <>
                        <ChargeScheduleMessage latestAction={this.state.device.latestAction} className="charge-schedule-ev" />
                        <div className="Ev__secondRow">
                            <div className="Ev__secondRowFirst">
                                <div className="Ev__secondRowTitle">
                                    <p>{text[this.context.language].myDevices.complete_charge_percentage}</p>
                                    <InfoTooltip tooltip={text[this.context.language].tooltips.battery_safety} type="info" />
                                </div>
                                <div className="Ev__Filter ev__range">
                                    <span className="ev__percentage" style={{ left: `${this.state.evPercentage - 3}px` }}>
                                        {this.state.evPercentage}%
                                    </span>
                                    <input
                                        type="range"
                                        min="0"
                                        max="100"
                                        value={this.state.evPercentage}
                                        onChange={event => this.evPercentageHandler(event)}
                                        style={{
                                            background: `linear-gradient(to right, #ee801c 0%, #ee801c ${this.state.evPercentage}%, #b0aeae ${this.state.evPercentage}%, #b0aeae 100%)`,
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="Ev__secondRowFirst">
                                <div className="Ev__secondRowTitle">
                                    <p>{text[this.context.language].myDevices.activate_smart}</p>
                                    <InfoTooltip tooltip={text[this.context.language].tooltips.start_smart_charging} type="info-activate" />
                                </div>
                                <div className="Ev__Filter">
                                    <select
                                        value={this.state.activateChargingAt}
                                        onChange={event => this.activateChargingHandler(event, this.state.device.id)}
                                        className="bold-text"
                                    >
                                        <option value="null">{text[this.context.language].myDevices.all_day}</option>
                                        <option value="7">07:00</option>
                                        <option value="8">08:00</option>
                                        <option value="9">09:00</option>
                                        <option value="10">10:00</option>
                                        <option value="11">11:00</option>
                                        <option value="12">12:00</option>
                                        <option value="13">13:00</option>
                                        <option value="14">14:00</option>
                                        <option value="15">15:00</option>
                                        <option value="16">16:00</option>
                                        <option value="17">17:00</option>
                                        <option value="18">18:00</option>
                                        <option value="19">19:00</option>
                                        <option value="20">20:00</option>
                                        <option value="21">21:00</option>
                                        <option value="22">22:00</option>
                                    </select>
                                </div>
                            </div>
                            <div className="Ev__secondRowFirst">
                                <div className="Ev__secondRowTitle">
                                    {text[this.context.language].myDevices.complete_charge_time}
                                    <InfoTooltip tooltip={text[this.context.language].tooltips.fully_charged} type="info" />
                                </div>
                                <div className="Ev__Filter">
                                    <select
                                        value={this.state.evTime}
                                        onChange={event => this.finishChargingHandler(event, this.state.device.id)}
                                        className="bold-text"
                                    >
                                        <option value="0">00:00</option>
                                        <option value="1">01:00</option>
                                        <option value="2">02:00</option>
                                        <option value="3">03:00</option>
                                        <option value="4">04:00</option>
                                        <option value="5">05:00</option>
                                        <option value="6">06:00</option>
                                        <option value="7">07:00</option>
                                        <option value="8">08:00</option>
                                        <option value="9">09:00</option>
                                        <option value="10">10:00</option>
                                        <option value="11">11:00</option>
                                        <option value="12">12:00</option>
                                        <option value="13">13:00</option>
                                        <option value="14">14:00</option>
                                        <option value="15">15:00</option>
                                        <option value="16">16:00</option>
                                        <option value="17">17:00</option>
                                        <option value="18">18:00</option>
                                        <option value="19">19:00</option>
                                        <option value="20">20:00</option>
                                        <option value="21">21:00</option>
                                        <option value="22">22:00</option>
                                        <option value="23">23:00</option>
                                    </select>
                                </div>
                            </div>
                            {this.renderSkipCharging()}
                        </div>
                    </>
                )}

                {!lostStatus && (
                    <div className="Ev__thirdRow">
                        <div className="Ev__thirdRowTitle">{text[this.context.language].myDevices.charging_location}</div>
                        {chargeSessionChart}
                    </div>
                )}
            </div>
        );
    }
}

export default Ev;

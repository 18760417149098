import React, { useContext } from 'react';
import AppContext from '../../../Containers/app-context';

const Button = ({ text, onClick, activeInterval }) => {
    const context = useContext(AppContext);

    return (
        <button
            style={{
                backgroundColor: context?.theme?.button_color || '#d4d0d0',
                color: context?.theme?.button_text_color || '#000000',
            }}
            onClick={() => onClick('hour')}
            className={activeInterval === 'hour' ? 'active graph-button' : 'graph-button'}
        >
            {text}
        </button>
    );
};

export default Button;

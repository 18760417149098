import React, { useContext, useEffect } from 'react';
import Loader from '../Common/Loader';
import AppContext from '../../Containers/app-context';
import API from '../../api';
import { useLocation } from 'react-router';
import { getStorageItem, removeStorageItem } from '../../storage';

const Tesla = () => {
    const context = useContext(AppContext);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const isErrorOccurred = location.search.includes('error');

    const onboardingActivated = getStorageItem('onboarding-access-only') === 'activated';

    const errorRedirect = () => (onboardingActivated ? context.onboardingRedirection(0) : (window.location.href = '/error'));
    const successRedirect = () => (onboardingActivated ? context.onboardingRedirection(1) : (window.location.href = '/success'));

    async function onboardDevice(code) {
        const api = new API(context.jwt_token);

        const selectedFacility = getStorageItem('selectedFacility');
        const facility = selectedFacility || context.facilities[0].facility_id;

        await api.onboard_device(code, facility, 'own_tesla').then(
            _ => {
                if (getStorageItem('tesla_state_check') !== queryParams.get('state')) {
                    errorRedirect();
                    return;
                }
                successRedirect();
            },
            _ => errorRedirect()
        );
    }

    useEffect(() => {
        if (getStorageItem('selected-manufacturer') === 'own_tesla') {
            if (isErrorOccurred) {
                errorRedirect();
            } else {
                const code = queryParams.get('code');
                if (code) {
                    onboardDevice(code);
                }
            }

            removeStorageItem('selected-manufacturer');
        }
    }, []);

    return <Loader />;
};

export default Tesla;

import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import PageVisibility from 'react-page-visibility';
import Layout from './hocs/Layout';
import Savings from './Containers/Savings/Savings';
import MyDevices from './Containers/MyDevices/MyDevices';
import AddDevices from './Containers/AddDevices/AddDevices';
import Faq from './Containers/Faq/Faq';
import AppContext from './Containers/app-context';
import jwt_decode from 'jwt-decode';
import Failure from './components/failure/failure';
import Mitsubishi from './components/Mitsubishi/Mitsubishi';
import Samsung from './components/Callback/Samsung';
import Callback from './components/Callback/Callback';
import Wallbox from './components/Wallbox/Wallbox';
import 'bootstrap/dist/css/bootstrap.min.css';
import API from './api';
import { reportMetric } from './report';
import Smartcar from '@smartcar/auth';
import text from './assets/translation.json';
import Loader from './components/Common/Loader/index';
import OnboardingAccess from './components/Onboarding/OnboardingAccess';
import Enode from './components/Enode/Enode';
import InitialOnboardPage from './components/Onboarding/InitialOnboardPage';
import { Flip, toast, ToastContainer } from 'react-toastify';
import OnboardingNotification from './components/OnboardingNotification';

import './App.css';
import { getStorageItem, removeStorageItem, setStorageItem } from './storage';
import { getCookie, storeJWTToCookie } from './cookies';
import Tesla from './components/Tesla/Tesla';
import TeslaSuccessPage from './components/Tesla/TeslaSuccessPage';
import Viessmann from './components/Viessmann/Viessmann';

const smartcar_template = {
    clientId: process.env.REACT_APP_SMARTCAR_CLIENT_ID,
    redirectUri: process.env.REACT_APP_SMARTCAR_REDIRECT_ONBOARDING,
    scope: ['read_vehicle_info', 'read_charge', 'control_charge', 'read_battery', 'read_odometer', 'read_location'],
};

class DummyHandler extends Component {
    constructor(props) {
        super(props);
        const params = new URLSearchParams(props.location.search);
        const token = params.get('token');
        if (token === 'hvac_token' || token === 'ev_token' || token === 'cs_token') {
            const goto = params.get('redirect_uri') + `?state=dummy&code=` + token;
            window.location.href = goto;
        }
    }

    render() {
        return '<div></div>';
    }
}

class App extends Component {
    constructor(props) {
        super(props);
        // If we have a token in the query string, always go for that.
        let jwt = new URLSearchParams(window.location.search).get('code');
        let jwt_content = null;
        try {
            jwt_content = jwt_decode(jwt);
        } catch (query_error) {
            // That failed; let's try to find one in local storage...
            jwt = localStorage.getItem('user_token');
            try {
                jwt_content = jwt_decode(jwt);
            } catch (local_error) {
                // That also failed, let's try the cookie also.
                try {
                    jwt = getCookie('emulate_api_token');
                    jwt_content = jwt_decode(jwt);
                    reportMetric('Found JWT in cookie instead of local storage', 'app.cookie-jwt', {
                        useragent: navigator.userAgent,
                        referrer: document.referrer,
                        url: window.location.href,
                    });
                } catch (cookie_error) {
                    // No way to do it then
                    this.state = {
                        jwt_content: null,
                        jwt_token: null,
                        error: true,
                    };
                    console.warn('Could not decode token');
                    console.warn(new Error(query_error));
                    console.warn(new Error(local_error));
                    console.warn(new Error(cookie_error));
                    reportMetric('Failed to find or decode JWT', 'app.missing-jwt', {
                        from_query_string: new URLSearchParams(window.location.search).get('code'),
                        from_local_storage: localStorage.getItem('user_token'),
                        from_cookie: getCookie('emulate_api_token'),
                        useragent: navigator.userAgent,
                        referrer: document.referrer,
                        url: window.location.href,
                    });
                }
            }
        }

        if (jwt_content !== null) {
            this._validate_jwt(jwt_content);
            this.state = {
                jwt_content: jwt_content,
                jwt_token: jwt,
                error: false,
            };
            try {
                localStorage.setItem('user_token', jwt);
            } catch (error) {
                reportMetric('Failed to store JWT to local storage', 'app.jwt-local-storage-error', {
                    error: JSON.stringify(error),
                    useragent: navigator.userAgent,
                    referrer: document.referrer,
                    url: window.location.href,
                });
            }
            storeJWTToCookie(jwt);
        }

        this.state = {
            ...this.state,
            modalShow: false,
            modalType: '',
            all: true,
            electricVehicles: false,
            chargingStations: false,
            heating: false,
            si: false,
            devices: [],
            weather: {},
            facilities: [],
            current_spotprice: [],
            consumption_hour: [],
            spotprice_hour: [],
            consumption_day: [],
            spotprice_day: [],
            consumption_week: [],
            spotprice_week: [],
            maxConsumption: 0,
            activeRequests: 7,
            showLoaderEnode: false,
            showSelectionModal: false,
            selectedDevice: {},
            enabledManufacturers: [],
        };

        let language = getStorageItem('language');
        if (language) {
            this.state.currentLanguage = language;
        } else {
            this.state.currentLanguage = 'swe';
            setStorageItem('language', 'swe');
        }

        // Set app state to handle sending metrics
        this.state.app_state = {
            started_onboarding: false,
            loaded_device_list: false,
            sent_early_leave: false,
        };
        this.updateAppState = this.updateAppState.bind(this);
        this.saveSmartcarResponse = this.saveSmartcarResponse.bind(this);

        // we'll hide the view before we have the data to apply retailer style
        this.state.loading = true;
        this.state.questions = this.getQuestions(this.state.currentLanguage);
    }

    _validate_jwt(jwt) {
        try {
            if (jwt.aud !== 'https://api.emulate.network/') {
                throw new Error("Token's 'aud' property is not correctly filled");
            }
            if (typeof jwt.retailer !== 'number') {
                throw new Error("Token's 'retailer' property is not correctly filled, epected a number");
            }
            if (typeof jwt.customer !== 'string') {
                throw new Error("Token's 'customer' property is not correctly filled, expected a string");
            }
            if (!Array.isArray(jwt.facilities)) {
                throw new Error("Token's 'facilities' property is not correctly filled, expected an array");
            }
            if (jwt.facilities.length === 0) {
                throw new Error("Token's 'facilities' property is empty");
            }
            for (let i = 0; i < jwt.facilities.length; i++) {
                if (typeof jwt.facilities[i].id !== 'string') {
                    throw new Error(`Token's 'facilities$[${i}].id' property is not correctly filled, expected a string`);
                }
                if (typeof jwt.facilities[i].address !== 'string') {
                    throw new Error(`Token's 'facilities$[${i}].address' property is not correctly filled, expected a string`);
                }
                if (jwt.facilities[i].price_area && typeof jwt.facilities[i].price_area !== 'string') {
                    throw new Error(`Token's 'facilities$[${i}].price_area property is not correctly filled, expected a string`);
                }
                if (jwt.facilities[i].grid_id && typeof jwt.facilities[i].grid_id !== 'string') {
                    throw new Error(`Token's 'facilities$[${i}].grid_id property is not correctly filled, expected a string`);
                }
            }
        } catch (error) {
            reportMetric('Invalid JWT was sent', 'app.invalid-jwt', {
                jwt: jwt,
            });
            throw error;
        }
    }

    async componentDidMount() {
        if (this.state.error) {
            return;
        }

        let api = new API(this.state.jwt_token);
        let sync_promise;
        await api.sync_facilities().then(response => {
            sync_promise = response;
            this.setState({ activeRequests: this.state.activeRequests - 1 });
        });
        let theme = (await api.retailer_theme()).data;

        this.setState({ theme });
        await this.applyRetailerTheme(theme);
        try {
            let facilities = (await sync_promise).data;
            let facility_id = facilities[0].facility_id;
            let enabledManufacturers = [];

            try {
                enabledManufacturers = (await api.enabled_manufacturers()).data;
            } catch (error) {
                console.error('An error happened while fetching manufacturers.', error);
            }

            let weather;
            await api.weather(facility_id).then(
                response => {
                    weather = response;
                    this.setState({ activeRequests: this.state.activeRequests - 1 });
                },
                error => {
                    this.setState({ activeRequests: this.state.activeRequests - 1 });
                }
            );

            let devices = [];
            await api.devices(facility_id).then(response => {
                devices = response.data;
                this.setState({ activeRequests: this.state.activeRequests - 1 });
            });
            setStorageItem('devices_status', devices.length);

            await Promise.all(
                devices.map(async device => {
                    let spo_info;
                    await api.spot_price_info(device.id).then(response => {
                        spo_info = response;
                    });
                    device.spo = spo_info.data;
                })
            );

            this.setState({ device_loading: false });
            this.setState({ devices: devices });

            let currentHour = new Date();
            currentHour.setUTCMinutes(0);
            currentHour.setUTCSeconds(0);
            currentHour.setUTCMilliseconds(0);
            let nextHour = new Date(currentHour);
            nextHour.setUTCHours(nextHour.getUTCHours() + 1);
            let spotprice = [];
            await api.spotprice(facility_id, 'hour', 'SEK', currentHour.getTime(), nextHour.getTime()).then(response => {
                spotprice = response.data;
                this.setState({ activeRequests: this.state.activeRequests - 1 });
            });

            this.setState({
                app_state: {
                    ...this.state.app_state,
                },
                weather: weather,
                facilities: facilities,
                current_spotprice: spotprice,
                enabledManufacturers: enabledManufacturers,
            });

            this.setChartData('hour');
            this.setChartData('week');
            this.setChartData('day');
        } catch (error) {
            // This may fail because the system doesn't know about the facility
            // yet due to no onboarded devices. Thus suppress a 404.
            if (!error.message.includes('404')) {
                throw error;
            }
        }
        this.setState({ loading: false });
    }

    async applyRetailerTheme(theme) {
        if ('page_title' in theme) {
            document.title = theme.page_title;
        }
        if ('favicon' in theme) {
            document.querySelector("link[rel*='icon']").href = `data:image/x-icon;base64,${theme.favicon}`;
        }

        this._applyFont(theme, 'regular_font', () => {
            document.querySelector('#root').style.fontFamily = `"${theme.regular_font}"`;
        });

        this._applyFont(theme, 'bold_font', () => {
            for (let sheet_counter = 0; sheet_counter < document.styleSheets.length; sheet_counter++) {
                for (let rule_counter = 0; rule_counter < document.styleSheets[sheet_counter].cssRules.length; rule_counter++) {
                    if (document.styleSheets[sheet_counter].cssRules[rule_counter].selectorText === '.bold-text') {
                        document.styleSheets[sheet_counter].cssRules[rule_counter].style['font-family'] = `"${theme.bold_font}"`;
                        return;
                    }
                }
            }
        });
    }

    async _applyFont(theme, font_style, apply_callback) {
        if (theme[font_style]) {
            if (theme[font_style + '_file']) {
                let base64 = theme[font_style + '_file'];
                let font_face = new FontFace(theme[font_style], this._base64ToArrayBuffer(base64));
                try {
                    let loaded_face = await font_face.load();
                    document.fonts.add(loaded_face);
                } catch (error) {
                    console.warn(`Could not load retailer font: ${font_style}`);
                    console.warn(new Error(error));
                    // don't try to apply the font if we couldn't load it
                    return;
                }
            }
            apply_callback();
        }
    }

    _base64ToArrayBuffer(base64) {
        let binary_string = window.atob(base64);
        let len = binary_string.length;
        let bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    }

    handleVisibilityChange = async isVisible => {
        // user has no devices and did not start onboarding and is leaving the tab
        let onboarding_status = getStorageItem('onboarding_status');
        if (onboarding_status !== 'true' && !isVisible && !this.state.app_state.sent_early_leave) {
            let devices_status = getStorageItem('devices_status');
            if (devices_status === null) {
                try {
                    let api = new API(this.state.jwt_token);
                    let devices = (await api.devices(this.state.facilities[0].facility_id)).data;
                    if (devices.length !== 0) {
                        return;
                    }
                } catch (error) {
                    console.warn('Could not connect with the server');
                    console.warn(new Error(error));
                }
            } else if (devices_status !== '0') {
                return;
            }
            reportMetric('The user left the application without any devices', 'onboarding.no-attempt', {
                retailer_id: this.state.jwt_content.retailer,
                customer: this.state.jwt_content.customer,
            });
            this.setState({ app_state: { ...this.state.app_state, sent_early_leave: true } });
        }
    };

    // a handler we pass to components to change this high-level
    // piece of state related to sending some metrics.
    // using Redux would be a more generic approach for this,
    // but as a simple matter this should do for now
    updateAppState(state) {
        this.setState({ app_state: { ...this.state.app_state, state } });
    }

    // Add devices methods
    // === === ===
    restoreAccess = async device => {
        const manufacturer = this.state.enabledManufacturers.find(d => d.id === device.manufacturer);
        this.addDevicesHandler(manufacturer);
    };

    addDevicesHandler = manufacturer => {
        if (manufacturer.id === 'nibe_s') {
            this.nibeUplink();
            return;
        }

        if (manufacturer.id === 'own_tesla') {
            setStorageItem('selected-manufacturer', manufacturer.id.toLowerCase());
            this.ownTeslalink();
            return;
        }

        if (manufacturer.id === 'viessmann') {
            setStorageItem('selected-manufacturer', manufacturer.id.toLowerCase());
            this.viessmannLink();
            return;
        }

        switch (manufacturer.aggregator) {
            case 'smartcar':
                this.authorizeSmartcar(manufacturer.label);
                break;
            case 'enode':
                this.eNodeOnboarding(manufacturer);
                break;
            case 'emulate':
                window.open(`/onboard/${manufacturer.id}`, '_self');
                break;
            default:
                window.open(`/onboard/${manufacturer.id}`, '_self');
        }
    };

    async eNodeOnboarding(device) {
        this._startMetric(device.label);
        this.setState({ showLoaderEnode: true });
        const api = new API(this.state.jwt_token);

        const selectedFacility = getStorageItem('selectedFacility');
        const facility = selectedFacility || this.state.facilities[0].facility_id;

        await setStorageItem('enode-device', device.label.toUpperCase());

        let vendorType;
        if (device.type === 'ev') {
            vendorType = 'vehicle';
        } else if (device.type === 'cs') {
            vendorType = 'charger';
        } else if (device.type === 'si') {
            vendorType = 'inverter';
        } else if (device.type === 'hvac') {
            vendorType = 'hvac';
        }

        let url = await api.enode_link(
            device.label.toUpperCase(),
            facility,
            process.env.REACT_APP_SMARTCAR_REDIRECT_ONBOARDING,
            process.env.REACT_APP_ENODE_REDIRECTION,
            vendorType
        );

        window.open(url.link_url, '_self');
    }

    catagoryHandler = e => {
        e.preventDefault();
        let category = e.target.innerHTML;
        this.setState({
            all: category === text[this.state.currentLanguage].addNewDevices.all,
            electricVehicles: category === text[this.state.currentLanguage].addNewDevices.ev,
            chargingStations: category === text[this.state.currentLanguage].addNewDevices.cs,
            heating: category === text[this.state.currentLanguage].addNewDevices.heating,
            si: category === text[this.state.currentLanguage].addNewDevices.si,
        });
    };

    ownTeslalink = () => {
        let link = `https://auth.tesla.com/oauth2/v3/authorize?client_id=${process.env.REACT_APP_TESLA_CLIENT_ID}&locale=en-US&prompt=login&redirect_uri=${process.env.REACT_APP_TESLA_REDIRECT_URI}&response_type=code&state=${process.env.REACT_APP_TESLA_STATE_CHECK}&scope=openid%20user_data%20vehicle_device_data%20offline_access%20vehicle_cmds%20vehicle_charging_cmds%20energy_device_data%20energy_cmds`;
        setStorageItem('tesla_state_check', process.env.REACT_APP_TESLA_STATE_CHECK);
        window.open(link, '_parent');
    };

    nibeUplink = () => {
        this._startMetric('nibe_s');
        let link = `https://api.myuplink.com/oauth/authorize?response_type=code&client_id=${process.env.REACT_APP_NIBE_S_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_NIBE_S_REDIRECT_URI}&state=NIBES&scope=WRITESYSTEM%20READSYSTEM%20offline_access`;
        window.open(link, '_parent');
    };

    viessmannLink = () => {
        let link = `https://iam.viessmann.com/idp/v3/authorize?response_type=code&client_id=${process.env.REACT_APP_VIESSMANN_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_VIESSMANN_REDIRECT_URI}&scope=IoT%20User%20offline_access&code_challenge=Ijxnns_poIFO2BfCg5A_UizAS6fO231twP5yyd5JGLM&code_challenge_method=S256`;
        window.open(link, '_parent');
    };

    nibe = () => {
        this._startMetric('nibe');
        let link = `https://api.nibeuplink.com/oauth/authorize?response_type=code&client_id=${process.env.REACT_APP_NIBE_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_NIBE_REDIRECT_URI}&state=NIBE&scope=WRITESYSTEM%20READSYSTEM`;
        window.open(link, '_parent');
    };

    // Mock tester devices
    mock_hvac = () => {
        let link = `/dummy-device-authorize?code=${this.state.jwt_token}&response_type=code&token=hvac_token&redirect_uri=http://localhost:3001/callback`;
        window.open(link, '_parent');
    };

    mock_ev = () => {
        let link = `/dummy-device-authorize?code=${this.state.jwt_token}&response_type=code&token=ev_token&redirect_uri=http://localhost:3001/callback`;
        window.open(link, '_parent');
    };

    mock_cs = () => {
        let link = `/dummy-device-authorize?code=${this.state.jwt_token}&response_type=code&token=cs_token&redirect_uri=http://localhost:3001/callback`;
        window.open(link, '_parent');
    };

    _startMetric = manufacturer => {
        setStorageItem('onboarding_status', true);
        reportMetric('Started onboarding', 'onboarding.start', {
            retailer_id: this.state.jwt_content.retailer,
            manufacturer,
            customer: this.state.jwt_content.customer,
            useragent: navigator.userAgent,
            referrer: document.referrer,
            url: window.location.href,
        });
    };

    saveSmartcarResponse(code) {
        const api = new API(this.state.jwt_token);
        const config = {
            headers: {
                'content-type': 'application/json',
            },
        };

        const selectedFacility = getStorageItem('selectedFacility');
        const facility = selectedFacility || this.state.facilities[0].facility_id;

        api.client
            .post(
                `/onboarding/smartcar/${facility}/devices`,
                JSON.stringify({
                    auth: code,
                    manufacturer: getStorageItem('smartcar-manufacturer'),
                }),
                config
            )
            .then(res => {
                this.setState({
                    smartcar_success: true,
                });

                getStorageItem('onboarding-access-only') === 'activated' ? this.onboardingRedirection(1) : (window.location.href = '/success');
                removeStorageItem('smartcar-manufacturer');
            })
            .catch(err => {
                getStorageItem('onboarding-access-only') === 'activated' ? this.onboardingRedirection(0) : (window.location.href = '/error');

                console.error(err);
                removeStorageItem('smartcar-manufacturer');
            });
    }

    showToastNotification = (state, message, location) => {
        let toast_settings = {
            position: 'top-center',
            autoClose: 1000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            transition: Flip,
            closeButton: false,
            onClose: () => (window.location.href = location),
        };

        toast[state](message, toast_settings);
    };

    onboardingRedirection = state => {
        let callbackURL = getStorageItem('onboarding-url');
        let onboardingActive = getStorageItem('onboarding-access-only');

        if (window.location.pathname.startsWith('/onboarding') || onboardingActive === 'activated') {
            if (callbackURL) {
                callbackURL.includes('?')
                    ? window.open(callbackURL + `&success=${state}`, '_self')
                    : window.open(callbackURL + `?success=${state}`, '_self');
            } else {
                window.close();
            }

            removeStorageItem('onboarding-url');
        }

        removeStorageItem('selectedFacility');
        removeStorageItem('onboarding-access-only');
    };

    onCompleteSmartcar = (err, code, _state) => {
        if (err === null) {
            this.saveSmartcarResponse(code);
        } else {
            getStorageItem('onboarding-access-only') === 'activated' ? this.onboardingRedirection(0) : (window.location.href = '/error');
        }
    };

    async authorizeSmartcar(make) {
        let smartcar;

        await setStorageItem('smartcar-manufacturer', make.toLowerCase());
        await setStorageItem('smartcar-onboarding', 'activated');

        smartcar = new Smartcar({
            ...smartcar_template,
            onComplete: this.onCompleteSmartcar.bind(this),
        });

        this._startMetric(make.toLowerCase());
        let options = {
            forcePrompt: true,
            flags: ['country:SE'],
            vehicleInfo: { make },
        };

        let href = smartcar.getAuthUrl(options);
        window.open(href, '_self');
    }

    modalSettings = device => {
        this._startMetric(device.id);
        this.setState({ modalShow: true, modalType: device.id });
    };

    hideModal = () => {
        this.setState({ modalShow: false });
        this.setState({ showSelectionModal: false });
    };
    // === === ===
    // End devices methods

    selectLanguage = value => {
        setStorageItem('language', value);
        this.setState({ currentLanguage: value });
        this.setState({ questions: this.getQuestions(value) });
    };

    getQuestions = language => {
        return [
            {
                id: 1,
                title: text[language].faq.q1,
                info: text[language].faq.a1,
            },
            {
                id: 2,
                title: text[language].faq.q2,
                info: text[language].faq.a2,
            },
            {
                id: 3,
                title: text[language].faq.q3,
                info: text[language].faq.a3,
            },
            {
                id: 4,
                title: text[language].faq.q4,
                info: text[language].faq.a4,
            },
            {
                id: 5,
                title: text[language].faq.q5,
                info: text[language].faq.a5,
            },
            {
                id: 6,
                title: text[language].faq.q6,
                info: text[language].faq.a6,
            },
            {
                id: 7,
                title: text[language].faq.q7,
                info: text[language].faq.a7,
            },
            {
                id: 8,
                title: text[language].faq.q8,
                info: text[language].faq.a8,
            },
            {
                id: 9,
                title: text[language].faq.q9,
                info: text[language].faq.a9,
            },
            {
                id: 10,
                title: text[language].faq.q10,
                info: text[language].faq.a10,
            },
            {
                id: 11,
                title: text[language].faq.q11,
                info: text[language].faq.a11,
            },
            {
                id: 12,
                title: text[language].faq.q12,
                info: text[language].faq.a12,
            },
            {
                id: 13,
                title: text[language].faq.q13,
                info: text[language].faq.a13,
            },
        ];
    };

    //Consumption
    // === === ===
    getChartData = async (interval_type, start) => {
        let api = new API(this.state.jwt_token);
        let facility_id = this.state.facilities[0].facility_id;
        if (facility_id == null) {
            return;
        }
        // Aggregate the multi-device data by date.
        let resp = [];
        await api.facility_consumption(facility_id, interval_type, start).then(response => {
            resp = response;
        });
        let data = resp.data.data || [];

        let aggregated = {};
        for (let interval of data) {
            let agg = (aggregated[interval.date] ||= {
                hvac: 0.0,
                ev: 0.0,
                cs: 0.0,
                other: 0.0,
            });
            switch (interval.type) {
                case 'hvac':
                    agg.hvac += interval.consumption;
                    break;
                case 'ev':
                    agg.ev += interval.consumption;
                    break;
                case 'cs':
                    agg.cs += interval.consumption;
                    break;
                default:
                    agg.other += interval.consumption;
                    break;
            }
        }

        // Now build result set from the dates.
        let result = [];
        for (let date of Object.keys(aggregated).sort(this.dateSorter)) {
            result.push({ date, ...aggregated[date] });
        }
        return result;
    };

    dateSorter(a, b) {
        let ats = new Date(a).getTime();
        let bts = new Date(b).getTime();
        return ats > bts ? 1 : ats < bts ? -1 : 0;
    }

    getStart = interval_type => {
        let start = new Date();
        switch (interval_type) {
            case 'hour':
                start.setDate(start.getDate() - 2);
                break;
            case 'day':
                start.setDate(start.getDate() - 31);
                break;
            case 'week':
                start.setMonth(start.getMonth() - 3);
                break;
            default:
                start.setDate(start.getDate() - 1);
                break;
        }
        start.setHours(start.getHours() + 1);
        start.setMinutes(0);
        start.setSeconds(0);
        start.setMilliseconds(0);

        return start;
    };

    setChartData = async interval_type => {
        let api = new API(this.state.jwt_token);
        let facility_id = this.state.facilities[0].facility_id;
        if (facility_id == null) {
            return;
        }

        let start = this.getStart(interval_type);

        let spotprice = [];
        await api.spotprice(facility_id, interval_type, 'SEK', start.getTime()).then(response => {
            spotprice = response.data;
            this.setState({ activeRequests: this.state.activeRequests - 1 });
        });

        this.setState({
            [`spotprice_${interval_type}`]: spotprice,
        });
    };

    loadChart() {
        this.setChartData('hour');
        this.setChartData('week');
        this.setChartData('day');
    }
    // === === ===
    // End consumption

    render() {
        return (
            <PageVisibility onChange={this.handleVisibilityChange}>
                <AppContext.Provider
                    value={{
                        jwt_content: this.state.jwt_content,
                        jwt_token: this.state.jwt_token,
                        theme: this.state.theme,
                        mock_ev: this.mock_ev,
                        mock_hvac: this.mock_hvac,
                        mock_cs: this.mock_cs,
                        addDevicesHandler: this.addDevicesHandler,
                        restoreAccess: this.restoreAccess,
                        modalShow: this.state.modalShow,
                        modalType: this.state.modalType,
                        hideModal: this.hideModal,
                        catagoryHandler: this.catagoryHandler,
                        all: this.state.all,
                        electricVehicles: this.state.electricVehicles,
                        chargingStations: this.state.chargingStations,
                        heating: this.state.heating,
                        si: this.state.si,
                        devices: this.state.devices,
                        weather: this.state.weather,
                        facilities: this.state.facilities,
                        current_spotprice: this.state.current_spotprice,
                        consumption_hour: this.state.consumption_hour,
                        spotprice_hour: this.state.spotprice_hour,
                        consumption_day: this.state.consumption_day,
                        spotprice_day: this.state.spotprice_day,
                        consumption_week: this.state.consumption_week,
                        spotprice_week: this.state.spotprice_week,
                        selectLanguage: this.selectLanguage,
                        language: this.state.currentLanguage,
                        questions: this.state.questions,
                        maxConsumption: this.state.maxConsumption,
                        activeRequests: this.state.activeRequests,
                        getChartData: this.getChartData,
                        getStart: this.getStart,
                        startMetric: this._startMetric,
                        smartcar_success: this.state.smartcar_success,
                        onboardingRedirection: this.onboardingRedirection,
                        onComplete: this.saveSmartcarResponse,
                        showSelectionModal: this.state.showSelectionModal,
                        selectedDevice: this.state.selectedDevice,
                        showToastNotification: this.showToastNotification,
                        modalSettings: this.modalSettings,
                        enabledManufacturers: this.state.enabledManufacturers,
                    }}
                >
                    {this.state.error && (
                        <p>Sorry, there was a problem with the provided customer information. Please contact your electricity retailer for help.</p>
                    )}
                    {!this.state.error && (
                        <>
                            {this.state.activeRequests !== 0 || this.state.showLoaderEnode ? (
                                <Loader />
                            ) : (
                                <div>
                                    <ToastContainer />
                                    <BrowserRouter>
                                        <Layout theme={this.state.theme}>
                                            <Switch>
                                                <Route exact path="/callback" component={Callback} />
                                                <Route exact path="/viessmann" component={Viessmann} />
                                                <Route exact path="/wallbox" component={Wallbox} />
                                                <Route exact path="/mitsubishi" component={Mitsubishi} />
                                                <Route exact path="/samsung" component={Samsung} />
                                                <Route exact path="/savings" component={() => <Savings />} />
                                                <Route exact path="/mydevices" component={() => <MyDevices updateAppState={this.updateAppState} />} />
                                                <Route
                                                    exact
                                                    path="/adddevices"
                                                    component={() => <AddDevices updateAppState={this.updateAppState} />}
                                                />
                                                <Route exact path="/faq" component={Faq} />
                                                <Route exact path="/failure" component={Failure} />
                                                <Route exact path="/onboarding/:manufacturer" component={() => <OnboardingAccess />} />
                                                <Route exact path="/onboarding" component={() => <OnboardingAccess />} />
                                                {process.env.NODE_ENV === 'development' && (
                                                    <Route exact path="/dummy-device-authorize" component={DummyHandler} />
                                                )}
                                                <Route exact path="/" component={() => <Savings />} />
                                                <Route exact path="/enode" component={() => <Enode />} />
                                                <Route exact path="/tesla" component={() => <Tesla />} />
                                                <Route exact path="/onboard/:device" component={() => <InitialOnboardPage />} />
                                                <Route exact path="/success" component={() => <OnboardingNotification success={true} />} />
                                                <Route exact path="/success/tesla" component={() => <TeslaSuccessPage />} />
                                                <Route exact path="/error" component={() => <OnboardingNotification success={false} />} />
                                            </Switch>
                                        </Layout>
                                    </BrowserRouter>
                                </div>
                            )}
                        </>
                    )}
                </AppContext.Provider>
            </PageVisibility>
        );
    }
}

export default App;

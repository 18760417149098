import React, { useContext, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import AuthModal from '../../AuthModal/AuthModal';
import AppContext from '../../../Containers/app-context';

export default function PopUp(props) {
    const context = useContext(AppContext);
    const [device, setDevice] = useState();

    useEffect(async () => {
        setDevice(context.enabledManufacturers.find(d => d.id === props.type));
    }, []);

    if (props.type === '') {
        return <></>;
    }

    return (
        <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton></Modal.Header>

            <Modal.Body>{device && <AuthModal device={device} />}</Modal.Body>
        </Modal>
    );
}

import React from 'react';
import './style.css';
import text from '../../assets/translation.json';
import { useContext } from 'react';
import AppContext from '../../Containers/app-context';

const OnboardingNotification = ({ success }) => {
    const context = useContext(AppContext);

    const redirection = () => {
        if (context.onboardingActive === 'activated') {
            success ? context.onboardingRedirection(1) : context.onboardingRedirection(0);
        } else {
            window.location.href = '/adddevices';
        }
    };

    return (
        <div className="notification-wrapper">
            {!success ? (
                <div className="notification">
                    <i className="fa-solid fa-xmark"></i>
                    <div className="button-wrap">
                        <h4>{text[context.language].addNewDevices.something_went_wrong}</h4>
                        <p>{text[context.language].addNewDevices.error_onboarding}</p>
                        <div className="buttons">
                            <button
                                className="cancel-button"
                                onClick={() => redirection()}
                                style={{
                                    color: context?.theme?.button_text_color || '#ffffff',
                                    backgroundColor: context?.theme?.button_color || '#ee801c',
                                }}
                            >
                                {text[context.language].addNewDevices.retry}
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="notification">
                    <i className="fa-solid fa-check"></i>
                    <div className="button-wrap">
                        <h4>{text[context.language].addNewDevices.success}</h4>
                        <p>{text[context.language].addNewDevices.success_onboarding}</p>
                        <div className="buttons">
                            <button
                                className="cancel-button"
                                onClick={() => redirection()}
                                style={{
                                    color: context?.theme?.button_text_color || '#ffffff',
                                    backgroundColor: context?.theme?.button_color || '#ee801c',
                                }}
                            >
                                {text[context.language].addNewDevices.confirm}
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default OnboardingNotification;

import React from 'react';
import Popup from 'reactjs-popup';
import './styles.css';

const NotificationModal = ({ modalOpen, modalCloseHandler, title, message }) => {
    return (
        <div>
            <Popup className={'custom-popup notification-popup'} open={modalOpen} onClose={modalCloseHandler} closeOnDocumentClick={false}>
                <button className="close" onClick={modalCloseHandler}>
                    &#10005;
                </button>
                <div className="cancel-container">
                    <div className="popup-title notification-title">{title}</div>
                </div>
                <div className="content">
                    <p className="notification-text">{message}</p>
                </div>
            </Popup>
        </div>
    );
};

export default NotificationModal;

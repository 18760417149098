import React, { useContext } from 'react';
import text from '../../../assets/translation.json';
import AppContext from '../../app-context';
import cc from 'classcat';
import { DateTime } from 'luxon';

const possibleMessages = [
    'CS_SPO_DISABLED',
    'EV_SPO_DISABLED',
    'CS_SPO_POSTPONED',
    'EV_SPO_POSTPONED',
    'CS_PASSED_DEADLINE',
    'CS_DEMAND_EXCEEDED',
    'EV_DEMAND_EXCEEDED',
    'CS_NO_FLEXIBILITY',
    'EV_NO_FLEXIBILITY',
    'CS_AWAITING_PRICES',
    'EV_AWAITING_PRICES',
    'CS_STOP_CHARGING',
    'EV_STOP_CHARGING',
    'CS_START_CHARGING',
    'EV_START_CHARGING',
    'CS_CHARGING_COMPLETE',
    'EV_CHARGING_COMPLETE',
    'EV_PASSED_DEADLINE',
    'EV_POSTPONED_SAFE_CHARGING',
    'EV_SAFE_CHARGING',
    'CS_UNPLUGGED',
    'EV_UNPLUGGED',
];

export const ChargeScheduleMessage = ({ latestAction, className }) => {
    const context = useContext(AppContext);

    const getChargeScheduleMessage = () => {
        if (latestAction.length === 0) {
            return `${text[context.language].myDevices.status_underway}`;
        }

        if (!possibleMessages.includes(latestAction[0].message)) {
            return `${text[context.language].myDevices.description_unavailable} ${latestAction[0].message}`;
        }

        return `${text[context.language].chargeSchedule.find(x => latestAction[0].message.includes(x.enumValue)).message}`;
    };

    const formatDate = () => {
        if (latestAction.length > 0) {
            return DateTime.fromISO(latestAction[0].updated_at).toFormat('MMM dd, yyyy, HH:MM');
        }

        return '';
    };

    return (
        <div className={cc(['charge-schedule-row', className])}>
            <div className="charge-schedule-title Ev__secondRowTitle">
                {text[context.language].myDevices.current_status}
                <p className="charge-schedule-updated-at">{formatDate()}</p>
            </div>
            <p className="charge-schedule-description">{getChargeScheduleMessage()}</p>
        </div>
    );
};

import React, { useContext, useEffect, useState } from 'react';
import './Layout.css';
import Navbar from '../components/Navbar/Navbar';
import { useLocation } from 'react-router';
import NotificationModal from '../components/Notifications/NotificationsModal';
import AppContext from '../Containers/app-context';
import API from '../api';
import text from '../assets/translation.json';
import { getStorageItem } from '../storage';

const Layout = props => {
    const onboardingActive = getStorageItem('onboarding-access-only');
    const location = useLocation();
    const context = useContext(AppContext);

    const [missingLocation, setMissingLocation] = useState(false);

    const modalCloseHandler = () => {
        setMissingLocation(false);
        document.body.style.overflow = 'auto';
    };

    const loadDevicesWithNotifications = async () => {
        let api = new API(context.jwt_token);
        let devices = [];
        await api.devices(context.facilities[0].facility_id).then(response => {
            devices = response.data;
        });

        await Promise.all(
            devices.map(async device => {
                let notifications;
                await api.device_notifications(device.id, false).then(response => {
                    notifications = response.data;
                });
                device.notifications = notifications;
            })
        );

        return devices;
    };

    useEffect(async () => {
        if (location.pathname !== '/faq') {
            const devices = await loadDevicesWithNotifications(context);
            devices.forEach(x => {
                const found = x.notifications.find(notification => notification.kind === 'MISSING_LOCATION');
                if (found) {
                    setMissingLocation(true);
                    document.body.style.overflow = 'hidden';
                }
            });
        }
    }, [location.pathname]);

    return (
        <div className="LayoutContainer">
            <NotificationModal
                modalOpen={missingLocation}
                modalCloseHandler={modalCloseHandler}
                title={text[context.language].notifications.missingLocation}
                message={text[context.language].notifications.locationMessage}
            />
            {onboardingActive !== 'activated' && !location.pathname.includes('/enode') && (
                <div className="LayoutNavbar" style={{ backgroundColor: props?.theme?.menu_bar_color || '#ffffff' }}>
                    <Navbar theme={props.theme} />
                </div>
            )}
            <div className="LayoutBody">{props.children}</div>
        </div>
    );
};

export default Layout;

import axios from 'axios';

function handle_response(response) {
    return response.data;
}

class API {
    constructor(token) {
        this.client = axios.create({
            baseURL: process.env.REACT_APP_CUSTOMER_API_URL || 'http://localhost:4000/v1/customer',
        });
        this.client.interceptors.request.use(
            config => {
                config.headers['Authorization'] = `Bearer ${token || 'dummy_token'}`;
                return config;
            },
            error => {
                Promise.reject(error);
            }
        );
    }

    async facilities() {
        return this.client.get(`/facilities`).then(handle_response);
    }

    async sync_facilities() {
        return this.client.post(`/facilities`).then(handle_response);
    }

    async weather(facility_id) {
        return this.client.get(`/facilities/${facility_id}/weather`).then(handle_response);
    }

    async spotprice(facility_id, interval_type, currency, from, to) {
        let query = `/facilities/${facility_id}/spotprice?interval_type=${interval_type}&currency=${currency}&from=${from}`;
        if (to) {
            query += '&to=' + to;
        }
        return this.client.get(query).then(handle_response);
    }

    async facility_consumption(facility_id, interval_type, from, to) {
        return this.client.get(`/facilities/${facility_id}/consumption`, {
            params: {
                interval_type,
                from: from.getTime(),
                ...(to ? { to: to.getTime() } : {}),
            },
        });
    }

    async devices(facility_id) {
        return this.client.get(`/facilities/${facility_id}/devices`).then(handle_response);
    }

    async spot_price_info(device_id) {
        return this.client.get(`/devices/${device_id}/spo`).then(handle_response);
    }

    async set_spot_price_optimized(device_id, on) {
        return this.client.post(`/devices/${device_id}/spo/enabled`, { on }).then(handle_response);
    }

    async set_device_settings(device_id, settings) {
        return this.client.post(`/devices/${device_id}/spo/settings`, settings).then(handle_response);
    }

    async delete_device(device_id) {
        return this.client.delete(`/devices/${device_id}`).then(handle_response);
    }

    async retailer_theme() {
        return this.client.get(`/web_app_settings`).then(handle_response);
    }

    async heat_curve_offset(device_id) {
        return this.client.get(`/devices/${device_id}/heat_curve_offset`).then(handle_response);
    }

    async set_heat_curve_offset(device_id, offset) {
        return this.client.post(`/devices/${device_id}/heat_curve_offset`, { heat_curve_offset: offset }).then(handle_response);
    }

    async savings_cost(deviceId, from, to) {
        return this.client.get(`/devices/${deviceId}/cost_and_savings?from=${from}&to=${to}`).then(handle_response);
    }

    async onboard_enode_device(facility_id, title) {
        const config = {
            headers: {
                'content-type': 'application/json',
            },
        };

        let body = {
            auth: {
                email: '',
                password: '',
            },
            manufacturer: title,
        };

        if (process.env.REACT_APP_IS_ENODE_SIMULATED === 'true') {
            body = { ...body, simulated_enode_device: true };
        }

        return this.client.post(`/onboarding/enode/${facility_id}/devices`, JSON.stringify(body), config).then(handle_response);
    }

    async onboard_device(code, facility_id, manufacturer) {
        const config = {
            headers: {
                'content-type': 'application/json',
            },
        };

        return this.client
            .post(
                `/onboarding/emulate/${facility_id}/devices`,
                JSON.stringify({
                    auth: code,
                    manufacturer,
                }),
                config
            )
            .then(handle_response);
    }

    async enode_link(manufacturer, unique_str, origin_url, redirect_uri, vendor_type) {
        let body = {
            manufacturer: manufacturer,
            unique_str: unique_str,
            origin_url: origin_url,
            redirect_uri: redirect_uri,
            vendor_type: vendor_type,
            simulated_enode_device: false,
        };

        if (process.env.REACT_APP_IS_ENODE_SIMULATED === 'true') {
            body = { ...body, simulated_enode_device: true };
        }

        return this.client.post('/enode/link_url', body).then(handle_response);
    }

    async enabled_manufacturers() {
        return this.client.get(`/manufacturers`).then(handle_response);
    }

    async edit_device_name(device_id, custom_device_name) {
        return this.client.patch(`/devices/${device_id}`, { custom_device_name }).then(handle_response);
    }

    async device_notifications(device_id, include_inactive) {
        return this.client.get(`/devices/${device_id}/notifications?include_inactive=${include_inactive}`).then(handle_response);
    }

    async spo_latest_action(device_id) {
        return this.client.get(`/devices/${device_id}/spo/latest_action`).then(handle_response);
    }
}

export default API;

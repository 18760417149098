import React, { Component } from 'react';
import { ToastContainer, toast, Flip } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Ev from './SubDevices/Ev';
import ChargeStation from './SubDevices/ChargeStation';
import Hvac from './SubDevices/Hvac';
import './MyDevices.css';
import API from '../../api';
import AppContext from '../app-context';
import text from '../../assets/translation.json';
import Loader from '../../components/Common/Loader/index';
import loadDevices from './loadDevices';
import PopUp from '../../components/modal/AuthModal/modal';
import DeleteDeviceModal from '../../components/modal/DeleteDeviceModal';
import EditDeviceModal from '../../components/modal/EditDeviceNameModal';
import { setStorageItem } from '../../storage';

const toast_settings = {
    position: 'top-center',
    autoClose: 500,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    transition: Flip,
    closeButton: false,
};

class MyDevices extends Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.handler = this.handler.bind(this);
        this.state = {
            ev: [],
            cs: [],
            hvac: [],
            devices: [],
            device_loading: true,
            tooltipMsgs: {
                pending: '',
                lost: '',
                unsupported: '',
            },
            deleteModalOpen: false,
            editModalOpen: false,
            modalAction: '',
            selectedDevice: '',
        };
    }

    device_del_handler = async (category, id) => {
        let devices = this.state.devices;
        let new_devices = devices.filter(device => device.id !== id);
        let state = {};
        state[category] = new_devices.filter(device => device.product_type === category);
        this.setState(state);
        this.setState({ devices: new_devices });
        let api = new API(this.context.jwt_token);
        try {
            await api.delete_device(id);
            toast.success(text[this.context.language].myDevices.successful_remove, toast_settings);
            this.closeModal();
            setStorageItem('devices_status', new_devices.length);
        } catch (error) {
            toast.error(text[this.context.language].myDevices.could_not_delete, toast_settings);
        }
    };

    async componentDidMount() {
        this.setState({
            tooltipMsgs: {
                pending: text[this.context.language].myDevices.tooltip_pending,
                lost: text[this.context.language].myDevices.tooltip_lost,
                unsupported: text[this.context.language].myDevices.tooltip_unsupported,
            },
        });

        if (this.context.facilities) {
            let devices = [];
            devices = await loadDevices(this.context);

            if (await this.checkDeviceSPO(devices)) {
                devices = await loadDevices(this.context);
            }

            this.setState({
                devices: devices,
                device_loading: false,
            });

            let ev = devices.filter(d => d.product_type === 'ev');
            let cs = devices.filter(d => d.product_type === 'cs');
            let hvac = devices.filter(d => d.product_type === 'hvac');
            setStorageItem('devices_status', devices.length);
            this.setState({
                jwt_content: this.context.jwt_content,
                jwt_token: this.context.jwt_token,
                ev,
                cs,
                hvac,
                device_loading: false,
            });
        }
    }

    checkDeviceSPO = async devices => {
        let foundEv = [];
        let foundCs = [];
        let changesMade = false;
        let api = new API(this.state.jwt_token);

        devices.forEach(d => {
            if (d.product_type === 'ev' && d.state === 'active' && d.spo.enabled === true) {
                foundEv.push(d);
            }

            if (d.product_type === 'cs' && d.spo.enabled === true) {
                foundCs.push(d);
            }
        });

        if (foundEv.length > 0 && foundCs.length > 0) {
            changesMade = true;
            foundCs.forEach(async x => {
                await api.set_spot_price_optimized(x.id, false);
            });
        }

        return changesMade;
    };

    handler(type, device) {
        let state = {};
        state[type] = this.state[type].map(d => (d.id === device.id ? device : d));
        state['devices'] = this.state.devices.map(d => (d.id === device.id ? device : d));
        this.setState(state);
    }

    openDeleteModal = device => {
        this.setState({
            modalAction: 'delete',
            selectedDevice: device.id,
            deleteModalOpen: true,
        });
    };

    openEditModal = device => {
        this.setState({
            modalAction: 'edit',
            selectedDevice: device.id,
            editModalOpen: true,
        });
    };

    closeModal = () => {
        this.setState({
            deleteModalOpen: false,
            selectedDevice: '',
            editModalOpen: false,
        });
    };

    editHandler = async (name, device) => {
        let api = new API(this.context.jwt_token);

        try {
            if (device.custom_device_name !== name || device.name !== name) {
                await api.edit_device_name(device.id, name);

                let devices = this.state.devices.map(d => {
                    if (d.id === device.id) {
                        d.custom_device_name = name;
                        return d;
                    } else {
                        return d;
                    }
                });

                this.setState({ devices: devices });
                toast.success(text[this.context.language].myDevices.successful_edit, toast_settings);
            }

            this.closeModal();
        } catch (error) {
            toast.error(text[this.context.language].addNewDevices.something_went_wrong, toast_settings);
        }
    };

    productName(device) {
        if (device.custom_device_name) {
            return device.custom_device_name;
        } else {
            return device.name;
        }
    }

    render() {
        return (
            <div className="MyDevices">
                <PopUp type={this.context.modalType} show={this.context.modalShow} onHide={this.context.hideModal} />
                <ToastContainer />
                {this.state.device_loading ? (
                    <Loader />
                ) : (
                    <div className="MyDevices__container">
                        {this.context.facilities[0]?.retailer_facility_id && (
                            <div className="customer-number">
                                {text[this.context.language].savings.customer} #{this.context.jwt_content?.customer}
                            </div>
                        )}
                        <h3 className="bold-text" style={{ color: this.context?.theme?.title_color || '#000000' }}>
                            {text[this.context.language].navbar.myDevices}
                        </h3>

                        {this.state.devices.map(dev => {
                            return (
                                <div key={dev.id} className="devices-wrapper">
                                    {this.state.modalAction === 'delete' && this.state.selectedDevice === dev.id && (
                                        <DeleteDeviceModal
                                            modalCloseHandler={this.closeModal}
                                            modalOpen={this.state.deleteModalOpen}
                                            deleteDevice={() => this.device_del_handler(dev.product_type, dev.id)}
                                            device={dev.id}
                                        />
                                    )}

                                    {this.state.modalAction === 'edit' && this.state.selectedDevice === dev.id && (
                                        <EditDeviceModal
                                            modalCloseHandler={this.closeModal}
                                            modalOpen={this.state.editModalOpen}
                                            currentName={this.productName(dev)}
                                            editName={this.editHandler}
                                            device={dev}
                                        />
                                    )}

                                    {dev.product_type === 'ev' ? (
                                        <Ev
                                            id={dev.id}
                                            key={dev.id}
                                            device={dev}
                                            devices={this.state.devices}
                                            updateDevice={(device, type) => this.handler(type, device)}
                                            tooltipMsgs={this.state.tooltipMsgs}
                                            lostStatus={dev.connected === false}
                                            openDeleteModal={this.openDeleteModal}
                                            openEditModal={this.openEditModal}
                                            productName={this.productName}
                                        />
                                    ) : dev.product_type === 'cs' ? (
                                        <ChargeStation
                                            device={dev}
                                            devices={this.state.devices}
                                            key={dev.id}
                                            updateDevice={(device, type) => this.handler(type, device)}
                                            tooltipMsgs={this.state.tooltipMsgs}
                                            lostStatus={dev.connected === false}
                                            openDeleteModal={this.openDeleteModal}
                                            openEditModal={this.openEditModal}
                                            productName={this.productName}
                                        />
                                    ) : (
                                        <Hvac
                                            device={dev}
                                            devices={this.state.devices}
                                            key={dev.id}
                                            updateDevice={(device, type) => this.handler(type, device)}
                                            tooltipMsgs={this.state.tooltipMsgs}
                                            lostStatus={dev.connected === false}
                                            openDeleteModal={this.openDeleteModal}
                                            openEditModal={this.openEditModal}
                                            productName={this.productName}
                                        />
                                    )}
                                </div>
                            );
                        })}

                        {this.state.devices.length === 0 ? (
                            <div className="start-saving">
                                {text[this.context.language].myDevices.addDevicesStart}
                                <button
                                    onClick={() => (window.location.href = '/adddevices')}
                                    className="Saving__addtextbtn bold-text"
                                    style={{
                                        color: this.context?.theme?.button_text_color || '#ffffff',
                                        backgroundColor: this.context?.theme?.button_color || '#ee801c',
                                    }}
                                >
                                    <span>&#x2b;</span> {text[this.context.language].myDevices.addDev}
                                </button>
                            </div>
                        ) : (
                            <button
                                onClick={() => (window.location.href = '/adddevices')}
                                className="Saving__addtextbtn bold-text"
                                style={{
                                    color: this.context?.theme?.button_text_color || '#ffffff',
                                    backgroundColor: this.context?.theme?.button_color || '#ee801c',
                                }}
                            >
                                <span>&#x2b;</span> {text[this.context.language].myDevices.addDev}
                            </button>
                        )}
                    </div>
                )}
            </div>
        );
    }
}

export default MyDevices;

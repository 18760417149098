import React, { useEffect, useState } from 'react';
import './InitialOnboardPage.css';
import logo from '../../assets/logo.png';
import faqIcon from '../../assets/icons/info.png';
import { useLocation } from 'react-router';
import { useContext } from 'react';
import AppContext from '../../Containers/app-context';
import text from '../../assets/translation.json';
import PopUp from '../modal/AuthModal/modal';

const EnodeFirst = () => {
    const location = useLocation();
    const context = useContext(AppContext);
    const [device, setDevice] = useState('');

    useEffect(async () => {
        setDevice(context.enabledManufacturers.find(d => d.id === location.pathname.split('/')[2]));
    }, []);

    return (
        <div>
            <PopUp type={context.modalType} show={context.modalShow} onHide={context.hideModal} />
            <main className="wrapper">
                <div className="message-wrapper">
                    <div className="info-wrap">
                        <div className="information-ico">
                            <img src={faqIcon} alt="" />
                        </div>
                    </div>
                    <h1 className="title">{text[context.language].addNewDevices.title_onboarding}</h1>
                    <p>
                        <em>Emulate </em>
                        {text[context.language].addNewDevices.paragraph_onboarding}
                    </p>
                </div>

                <div className="image-wrapper">
                    <div className="images">
                        <div className="emulate-img logo">
                            <img src={logo} alt="Logo" />
                        </div>
                        <span className="horizontal-line"></span>
                        <div className="emulate-img">
                            <img src={device.logo_url} alt="Logo" />
                        </div>
                    </div>
                </div>

                <button className="continue-btn" onClick={() => context.modalSettings(device)}>
                    {text[context.language].addNewDevices.confirm}
                </button>
            </main>
        </div>
    );
};

export default EnodeFirst;

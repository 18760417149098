import React, { useState, useLayoutEffect } from 'react';
import useWindowSize from './useWindowSize';
import { NavLink } from 'react-router-dom';
import { withRouter } from 'react-router';

import savings from '../../assets/icons/savings.png';
import mydevices from '../../assets/icons/mydevices.png';
import adddevices from '../../assets/icons/adddevices.png';
import faq from '../../assets/icons/faq.png';
import savingsActive from '../../assets/icons/savingsActive.png';
import mydevicesActive from '../../assets/icons/mydevicesActive.png';
import adddevicesActive from '../../assets/icons/adddevicesActive.png';
import faqActive from '../../assets/icons/faqActive.png';
import AppContext from '../../Containers/app-context';
import './Navbar.css';
import { useContext } from 'react';
import text from '../../assets/translation.json';
import NavbarItem from './NavbarItem';

const SideNavbar = props => {
    const context = useContext(AppContext);
    const [mobileSize, setMobileSize] = useState(false);
    let url = props.location.pathname;
    url = url.split('/');
    const [width] = useWindowSize();
    useLayoutEffect(() => {
        if (width < 500) {
            setMobileSize(true);
        }
        if (width > 500) {
            setMobileSize(false);
        }
    }, [width]);

    function selectLanguage(e) {
        context.selectLanguage(e.target.value);
    }

    return (
        <ul className="Navbar">
            <li className="NavbarItem">
                <NavLink
                    exact
                    to="/savings"
                    className={'NavbarLink' + (url[1] === 'savings' ? ' NavbarLinkActive' : '') + ' bold-text'}
                    style={{
                        color: url[1] === 'savings' ? props?.theme?.menu_active_text_color || '#000000' : props?.theme?.menu_text_color || '#868686',
                    }}
                >
                    {mobileSize === true ? (
                        url[1] === 'savings' ? (
                            <NavbarItem className="mobileNav" imgSrc={savingsActive} text={text[context.language].navbar.savings} />
                        ) : (
                            <NavbarItem imgSrc={savings} />
                        )
                    ) : (
                        <NavbarItem className="desktop-nav" imgSrc={savings} text={text[context.language].navbar.savings} />
                    )}
                </NavLink>
            </li>

            <li className="NavbarItem">
                <NavLink
                    exact
                    to="/mydevices"
                    className={'NavbarLink' + (url[1] === 'mydevices' ? ' NavbarLinkActive' : '') + ' bold-text'}
                    style={{
                        color:
                            url[1] === 'mydevices' ? props?.theme?.menu_active_text_color || '#000000' : props?.theme?.menu_text_color || '#868686',
                    }}
                >
                    {mobileSize === true ? (
                        url[1] === 'mydevices' ? (
                            <NavbarItem className="mobileNav" imgSrc={mydevicesActive} text={text[context.language].navbar.myDevices} />
                        ) : (
                            <NavbarItem imgSrc={mydevices} />
                        )
                    ) : (
                        <NavbarItem className="desktop-nav" imgSrc={mydevices} text={text[context.language].navbar.myDevices} />
                    )}
                </NavLink>
            </li>

            <li className="NavbarItem">
                <NavLink
                    exact
                    to="/adddevices"
                    className={'NavbarLink' + (url[1] === 'adddevices' ? ' NavbarLinkActive' : '') + ' bold-text'}
                    style={{
                        color:
                            url[1] === 'adddevices' ? props?.theme?.menu_active_text_color || '#000000' : props?.theme?.menu_text_color || '#868686',
                    }}
                >
                    {mobileSize === true ? (
                        url[1] === 'adddevices' ? (
                            <NavbarItem className="mobileNav" imgSrc={adddevicesActive} text={text[context.language].navbar.addDevices} />
                        ) : (
                            <NavbarItem imgSrc={adddevices} />
                        )
                    ) : (
                        <NavbarItem className="desktop-nav" imgSrc={adddevices} text={text[context.language].navbar.addDevices} />
                    )}
                </NavLink>
            </li>

            <li className="NavbarItem">
                <NavLink
                    exact
                    to="/faq"
                    className={'NavbarLink' + (url[1] === 'faq' ? ' NavbarLinkActive' : '') + ' bold-text'}
                    style={{
                        color: url[1] === 'faq' ? props?.theme?.menu_active_text_color || '#000000' : props?.theme?.menu_text_color || '#868686',
                    }}
                >
                    {mobileSize === true ? (
                        url[1] === 'faq' ? (
                            <NavbarItem className="mobileNav" imgSrc={faqActive} text={text[context.language].navbar.faq} />
                        ) : (
                            <NavbarItem imgSrc={faq} />
                        )
                    ) : (
                        <NavbarItem className="desktop-nav" imgSrc={faq} text={text[context.language].navbar.faq} />
                    )}
                </NavLink>
            </li>

            <li className="NavbarItem">
                {mobileSize ? (
                    <select
                        className={'NavbarLink bold-text language'}
                        style={{ color: props?.theme?.menu_text_color || '#868686' }}
                        onChange={selectLanguage}
                        value={context.language}
                    >
                        <option value="swe">SWE</option>
                        <option value="en">EN</option>
                    </select>
                ) : (
                    <select
                        className={'NavbarLink bold-text language'}
                        style={{ color: props?.theme?.menu_text_color || '#868686' }}
                        onChange={selectLanguage}
                        value={context.language}
                    >
                        <option value="swe">Svenska</option>
                        <option value="en">English</option>
                    </select>
                )}
            </li>
        </ul>
    );
};

export default withRouter(SideNavbar);

import React from 'react';
import cc from 'classcat';
import 'reactjs-popup/dist/index.css';

import DeviceBase from './DeviceBase';
import AppContext from '../../app-context';
import text from '../../../assets/translation.json';
import './ChargeStation.css';
import InfoTooltip from '../../../components/Common/InfoTooltip';
import { ChargeScheduleMessage } from './ChargeScheduleMessage';

class ChargeStation extends DeviceBase {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            chargeStationTime: this.props.device.spo.settings.charged_by_hour,
            lostStatus: true,
        };
    }

    chargeStationTimeHandler = async (e, device_id) => {
        e.preventDefault();
        this.setState({ chargeStationTime: e.target.value });
        await this.api.set_device_settings(device_id, { charged_by_hour: parseInt(e.target.value, 10) });
    };

    render() {
        const deviceState = this.inferState();
        const lostStatus = this.props.lostStatus;

        let tooltip =
            deviceState === 'unsupported' && this.state.device.spo.unsupported_reason !== null
                ? this.state.device.spo.unsupported_reason
                : this.props.tooltipMsgs[deviceState];

        return (
            <div className="ChargeStation">
                {(deviceState === 'pending' || deviceState === 'lost' || deviceState === 'unsupported') && (
                    <div className={cc(['badge-indicator', deviceState])}>
                        <span className="dot"></span>
                        {text[this.context.language].myDevices[deviceState]}

                        <InfoTooltip tooltip={tooltip} type={`deviceStatus-${deviceState}`} />
                    </div>
                )}
                <div
                    className="Ev__crossCard"
                    onClick={() => {
                        this.props.openDeleteModal(this.state.device);
                    }}
                >
                    &#xf00d;
                </div>

                <div className="ChargeStation__firstRow">
                    <div className="ChargeStation__titleContainer">
                        <div className="ChargeStation__title bold-text">{this.props.productName(this.state.device)}</div>
                        <div
                            className="edit-icon"
                            onClick={() => {
                                this.props.openEditModal(this.state.device);
                            }}
                        >
                            &#x1F58A;
                        </div>
                    </div>

                    {lostStatus ? (
                        <div className="restore-btn">
                            <button
                                className="btn"
                                style={{
                                    backgroundColor: this.context?.theme?.button_color || '#aeaeae',
                                    color: this.context?.theme?.button_text_color || '#000000',
                                }}
                                onClick={() => this.context.restoreAccess(this.state.device)}
                            >
                                {text[this.context.language].myDevices.restore_access}
                            </button>
                        </div>
                    ) : (
                        this.renderSmartChargingSetting()
                    )}
                </div>

                {!lostStatus && (
                    <div className="settings-wrap">
                        <ChargeScheduleMessage latestAction={this.state.device.latestAction} className="charge-schedule-cs" />
                        <div className="Ev__secondRowFirst">
                            <div className="ChargeStation__secondRowTitle">{text[this.context.language].myDevices.complete_charge_time}</div>
                            <div className="ChargeStation__Filter">
                                <select
                                    onChange={event => this.chargeStationTimeHandler(event, this.state.device.id)}
                                    className="bold-text"
                                    value={this.state.chargeStationTime}
                                >
                                    <option value="0">00:00</option>
                                    <option value="1">01:00</option>
                                    <option value="2">02:00</option>
                                    <option value="3">03:00</option>
                                    <option value="4">04:00</option>
                                    <option value="5">05:00</option>
                                    <option value="6">06:00</option>
                                    <option value="7">07:00</option>
                                    <option value="8">08:00</option>
                                    <option value="9">09:00</option>
                                    <option value="10">10:00</option>
                                    <option value="11">11:00</option>
                                    <option value="12">12:00</option>
                                    <option value="13">13:00</option>
                                    <option value="14">14:00</option>
                                    <option value="15">15:00</option>
                                    <option value="16">16:00</option>
                                    <option value="17">17:00</option>
                                    <option value="18">18:00</option>
                                    <option value="19">19:00</option>
                                    <option value="20">20:00</option>
                                    <option value="21">21:00</option>
                                    <option value="22">22:00</option>
                                    <option value="23">23:00</option>
                                </select>
                            </div>
                        </div>
                        {this.renderSkipCharging()}
                    </div>
                )}
            </div>
        );
    }
}

export default ChargeStation;

/* Provides a mechanism for storage of small amounts of state, backed by both local
 * storage (used preferentially) and cookie storage (because local storage can get
 * obliterated in some cases, especially in mobile apps on iPhone). */

import { getCookie, storeStateToCookie } from './cookies';

const LOCAL_STORAGE_KEY = 'customer-app-state';
const COOKIE_NAME = 'emulate_state';

// Load current state at application load time.
let currentStorage = (() => {
    // Prefer stage from local storage.
    let localStorageState = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (localStorageState) {
        try {
            return JSON.parse(localStorageState);
        } catch {
            // Parsing issue with what was in local storage.
        }
    }

    // Also try it from cookie storage (need to Base-64 decode it first).
    let cookieState = getCookie(COOKIE_NAME);
    if (cookieState) {
        try {
            return JSON.parse(atob(cookieState));
        } catch {
            // Parsing issue with what was in the cookie.
        }
    }

    // Nothing in storage at present, so initialize to an empty object.
    return {};
})();

let cookieStorageChain = Promise.resolve();
function syncStorage() {
    // Save to local storage.
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(currentStorage));

    // Also save it to cookie storage. Note that since this is an async operation,
    // we chain it on to the Promise of the previous storage attempt in order to
    // not have ordering issues. We serialize it as JSON and then Base-64 that, so
    // it's safe for a HTTP header and cookie value.
    let serializedState = btoa(JSON.stringify(currentStorage));
    return cookieStorageChain.then(() => storeStateToCookie(serializedState));
}

export function setStorageItem(key, value) {
    // Historically we used separate local storage keys for each value, which means they
    // all became strings when stored. This restriction can be limited in the future now
    // we store them as a JSON blob, however out of not breaking anything at the time the
    // JSON storage was introduced, for now everything is coerced to a string.
    currentStorage[key] = String(value);
    return syncStorage();
}

export function getStorageItem(key) {
    return currentStorage[key];
}

export function removeStorageItem(key) {
    delete currentStorage[key];
    return syncStorage();
}

import TokenBasedAuth from './TokenBasedAuth';

class Callback extends TokenBasedAuth {
    constructor(props) {
        const url = window.location.href.split('?');
        let type, product;
        if (url.length > 1) {
            const urlParams = new URLSearchParams(window.location.search);
            type = urlParams.get('state');
            if (type === undefined || type === null) {
                type = '';
            } else if (type === 'honeywell') {
                type = 'honeywell';
            } else if (type === 'NIBE') {
                type = 'nibe';
            } else if (type === 'NIBES') {
                type = 'nibe_s';
            } else if (type === 'carrier') {
                type = 'carrier';
            } else if (type === 'ecobee') {
                type = 'ecobee';
            } else if (type === 'dummy') {
                type = 'dummy';
                // Set the product type explicitly because we have a single device manufacturer for mock devices
                // and without an explicit assignment a default one (HVAC) will be always used
                let product_code = urlParams.get('code');
                product = product_code === 'hvac_token' ? 'MOCK HVAC' : product_code === 'ev_token' ? 'MOCK EV' : 'MOCK CS';
            } else if (type === 'nest') {
                type = 'nest';
            } else {
                type = 'unknown';
            }
        }
        super(props, type, product);
    }
}

export default Callback;

import API from '../../api';

async function loadDevices(context) {
    let api = new API(context.jwt_token);
    let devices = [];
    await api.devices(context.facilities[0].facility_id).then(response => {
        devices = response.data;
    });

    await Promise.all(
        devices.map(async device => {
            let spo_info;
            let latestAction;
            await api.spot_price_info(device.id).then(response => {
                spo_info = response;
            });
            await api.spo_latest_action(device.id).then(response => {
                latestAction = response.data;
            });
            device.spo = spo_info.data;
            device.latestAction = latestAction;
        })
    );

    return devices;
}

export default loadDevices;

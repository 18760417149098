import React from 'react';
import './style.css';

const Battery = ({ charge, fillColor, emptyColor }) => {
    const fillStyle = {
        height: `calc(100% - ${charge}%)`,
        backgroundColor: emptyColor,
    };

    return (
        <>
            <div className="battery-shape"></div>
            <div style={{ backgroundColor: fillColor }} className="battery">
                <div style={fillStyle} className="battery-fill"></div>
            </div>
        </>
    );
};

export default Battery;

import React, { Component } from 'react';
import SingleQuestion from './SingleQuestion';
import './Faq.css';
import AppContext from '../app-context';
import text from '../../assets/translation.json';

class Faq extends Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {
            questions: [],
        };
    }

    componentDidMount() {
        this.setState({
            questions: this.context.questions,
        });
    }

    render() {
        return (
            <div className="Faq">
                <div className="Faq__container">
                    <h3 className="bold-text" style={{ color: this.context?.theme?.title_color || '#000000' }}>
                        {text[this.context.language].faq.faq}
                    </h3>
                    <section className="Faq__wrapper">
                        {this.context.questions.map(question => (
                            <SingleQuestion key={question.id} question={question} />
                        ))}
                    </section>
                </div>
            </div>
        );
    }
}

export default Faq;

import React, { Component } from 'react';
import Chart from 'react-google-charts';

import livePrice from '../../../assets/icons/livePrice.png';
import Sunny from '../../../assets/icons/Sunny.png';
import './SavingsWindow.css';
import AppContext from '../../app-context';
import text from '../../../assets/translation.json';
import ProgressBar from '../../../components/Common/ProgressBar';
import API from '../../../api';
import Loader from '../../../components/Common/Loader';
import InfoTooltip from '../../../components/Common/InfoTooltip';
import Button from '../../../components/Common/Button';

class SavingsWindow extends Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {
            ev_count: 0,
            ev_percentage: 0,
            ev_monthly_cost: 0,
            ev_monthly_savings: 0,
            hvac_count: 0,
            hvac_percentage: 0,
            hvac_monthly_cost: 0,
            hvac_monthly_savings: 0,
            cs_count: 0,
            cs_percentage: 0,
            cs_monthly_cost: 0,
            cs_monthly_savings: 0,
            viewport: {
                width: '',
                height: '',
            },
            stackedConsumption: [],
            chartSeries: {},
            livePrice: 'Loading...',
            spotprice_currency: '',
            facilities: [],
            devices: [],
            currency: 'SEK',
            activeInterval: 'hour',
            currencyTitle: 'SEK',
            maxConsumption: 0,
            minValueGraph: 0,
            maxValueGraph: 0,
            consumptionZero: true,
            activeRequests: true,
        };
    }

    async componentDidMount() {
        this._resize_mixin_callback();
        window.addEventListener('resize', this._resize_mixin_callback);
        let api = new API(this.context.jwt_token);

        if (this.state?.jwt_token === undefined) {
            // Obtain weather and current spot price from context.
            let spotprice = this.context.current_spotprice;
            let current_price;
            if (spotprice && spotprice[0]?.price) {
                current_price = (spotprice[0]?.price / 1000).toFixed(2);
            }
            let weather = this.context.weather?.data;

            // Load all devices, their SPO info, and their cost and savings.
            let facilities = this.context.facilities;
            let devices = (await api.devices(facilities[0].facility_id)).data;
            let end = new Date();
            let start = new Date();
            start.setDate(start.getDate() - 30);
            await Promise.all(
                devices.map(async device => {
                    let fetch_spo_info = api.spot_price_info(device.id);
                    let fetch_cost_savings = api.savings_cost(device.id, start.toISOString().slice(0, 10), end.toISOString().slice(0, 10));
                    device.spo = (await fetch_spo_info).data;
                    let cost_savings = (await fetch_cost_savings).data;
                    device.cost = cost_savings?.cost || 0;
                    device.savings = cost_savings?.savings || 0;
                })
            );

            let {
                count: ev_count,
                cost: ev_monthly_cost,
                savings: ev_monthly_savings,
                percentage: ev_percentage,
            } = this._aggregateDevices(devices, 'ev');
            let {
                count: hvac_count,
                cost: hvac_monthly_cost,
                savings: hvac_monthly_savings,
                percentage: hvac_percentage,
            } = this._aggregateDevices(devices, 'hvac');
            let {
                count: cs_count,
                cost: cs_monthly_cost,
                savings: cs_monthly_savings,
                percentage: cs_percentage,
            } = this._aggregateDevices(devices, 'cs');

            this.setState({
                jwt_content: this.context.jwt_content,
                jwt_token: this.context.jwt_token,
                spotprice: current_price || text[this.context.language].savings.noData,
                spotprice_currency: spotprice?.currency || 'SEK',
                weather_temp: weather?.timeStamp ? weather?.weather?.temp.toFixed(0) : text[this.context.language].savings.noData,
                weather_icon: weather?.timeStamp ? `https://openweathermap.org/img/wn/${weather.weather.icon}@2x.png` : undefined,
                facilities,
                ev_count,
                ev_percentage,
                ev_monthly_cost,
                ev_monthly_savings,
                hvac_count,
                hvac_percentage,
                hvac_monthly_cost,
                hvac_monthly_savings,
                cs_count,
                cs_percentage,
                cs_monthly_cost,
                cs_monthly_savings,
                devices,
            });

            if (weather?.timeStamp) {
                this.setState({
                    weather_description: weather.weather.description[this.context.language],
                });
            } else {
                this.setState({
                    weather_description: text[this.context.language].savings.noData,
                });
            }

            this._updateChartData('hour');
            this.setState({ maxConsumption: this.context.maxConsumption });
        }
    }

    _aggregateDevices(devices, type) {
        let count = 0;
        let cost = 0;
        let savings = 0;
        for (let device of devices.filter(d => d.product_type === type)) {
            count++;
            cost += device.cost;
            savings += device.savings;
        }
        let total = savings + cost;
        let percentage = ((100 * savings) / (total === 0 ? 1 : Math.abs(total))).toFixed(0);
        return { count, cost, savings, percentage };
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this._resize_mixin_callback);
    }

    _resize_mixin_callback = () => {
        this.setState({
            viewport: {
                width: document.documentElement.clientWidth,
                height: document.documentElement.clientHeight,
            },
        });
    };

    async _updateChartData(interval_type) {
        // Obtain data and spot prices to render on the graph
        let start = this.context.getStart(interval_type);
        let data = await this.context.getChartData(interval_type, start);
        let spotprice = this.context[`spotprice_${interval_type}`];

        // Clear loading state
        this.setState({ activeRequests: false });

        // Decide what time series to show and set them their styling
        let series = {
            hvac: this.state.hvac_count > 0,
            ev: this.state.ev_count > 0,
            // If we have EVs, don't show charge stations
            cs: this.state.cs_count > 0 && this.state.ev_count === 0,
        };
        let chartSeries = {};
        let index = 0;
        if (series.hvac) {
            chartSeries[index++] = { color: '#df536b', targetAxisIndex: 0 };
        }
        if (series.ev) {
            chartSeries[index++] = { color: '#0072B2', targetAxisIndex: 0 };
        }
        if (series.cs) {
            chartSeries[index++] = { color: '#2D3047', targetAxisIndex: 0 };
        }
        chartSeries[index++] = {
            type: 'line',
            color: this.context?.theme?.pie_highlight_color || '#ee801c',
            targetAxisIndex: 1,
        };

        // Axes, then data
        let stackedConsumption = [
            [
                'Hour',
                { role: 'annotation', type: 'string' },
                ...this.filterSeries(
                    series,
                    { label: `${text[this.context.language].savings.hvac_legend}`, type: 'number' },
                    { label: `${text[this.context.language].savings.ev_legend}`, type: 'number' },
                    { label: `${text[this.context.language].savings.cs_legend}`, type: 'number' }
                ),
                { label: `${text[this.context.language].savings.spotprice}`, type: 'number' },
                { role: 'tooltip', type: 'string' },
            ],
        ];
        this.addDataToChart(stackedConsumption, series, data, interval_type, spotprice);

        // Determine maximum price, for scaling.
        let minPrice = Math.min(...spotprice.map(o => o.price / 1000));
        var maxPrice = Math.max(...spotprice.map(o => o.price / 1000));
        this.setState({
            minValueGraph: minPrice - 1,
            maxValueGraph: maxPrice + 1,
            chartSeries,
            stackedConsumption,
        });
    }

    filterSeries(series, hvac, ev, cs) {
        return [...(series.hvac ? [hvac] : []), ...(series.ev ? [ev] : []), ...(series.cs ? [cs] : [])];
    }

    addDataToChart(stackedConsumption, series, data, interval_type, spotprice) {
        for (let i = 0; i < spotprice.length; i++) {
            let consumption = data.find(x => x.date === spotprice[i].startTime);

            let date = new Date(spotprice[i].startTime);
            date = new Date(
                date.toLocaleString('en-US', {
                    timeZone: 'Europe/Stockholm',
                })
            ); // Time gets converted but zone doesn't - it is not a blocker, we will fix it in the meantime

            let tooltip = `${date.toLocaleTimeString([], { year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' })}`;
            if (this.attachSpotprice(spotprice, i) !== 'No data') {
                tooltip += `\nSpotprice: ${this.attachSpotprice(spotprice, i).toFixed(3)}`;
            } else {
                tooltip += `\nSpotprice: No Data`;
            }

            let factor = this.valueScalingFactor(data, i, interval_type);
            stackedConsumption.push([
                date,
                null,
                ...this.filterSeries(series, consumption?.hvac * factor || 0, consumption?.ev * factor || 0, consumption?.cs * factor || 0),
                this.attachSpotprice(spotprice, i),
                tooltip,
            ]);
        }

        this.setGraphMaximum(data);
        this.getCurrentTime(interval_type, stackedConsumption);
    }

    setGraphMaximum(data) {
        if (data.length !== 0) {
            if (Math.max(...data.map(d => d.hvac)) === 0 && Math.max(...data.map(d => d.ev)) === 0 && Math.max(...data.map(d => d.cs)) === 0) {
                this.setState({ consumptionZero: true });
            } else {
                this.setState({ consumptionZero: false });
            }
        }
    }

    getCurrentTime(interval_type, stackedConsumption) {
        switch (interval_type) {
            case 'day':
                this.indicateCurrentDay(stackedConsumption);
                break;
            case 'week':
                this.indicateCurrentWeek(stackedConsumption);
                break;
            default:
                this.indicateCurrentHour(stackedConsumption);
        }
    }

    indicateCurrentWeek(stackedConsumption) {
        stackedConsumption.forEach(element => {
            var today = new Date();
            var first = today.getDate() - today.getDay() + 1;
            var firstDay = new Date(today.setDate(first)).setHours(0, 0, 0, 0);

            if (element[0] !== 'Hour' && firstDay === element[0].setHours(0, 0, 0, 0)) {
                element[1] = '';
            }
        });
    }

    indicateCurrentHour(stackedConsumption) {
        stackedConsumption.forEach(element => {
            var today = new Date();
            today = new Date(
                today.toLocaleString('en-US', {
                    timeZone: 'Europe/Stockholm',
                })
            );
            today.setHours(today.getHours(), 0, 0, 0);
            if (element[0] !== 'Hour' && today.getTime() === element[0].getTime()) {
                element[1] = '';
            }
        });
    }

    indicateCurrentDay(stackedConsumption) {
        stackedConsumption.forEach(element => {
            var today = new Date();
            today = new Date(
                today.toLocaleString('en-US', {
                    timeZone: 'Europe/Stockholm',
                })
            );
            today.setHours(0, 0, 0, 0);
            if (element[0] !== 'Hour' && today.getTime() === element[0].setHours(0, 0, 0, 0)) {
                element[1] = '';
            }
        });
    }

    attachSpotprice(spotprice, i) {
        return spotprice[i]?.price / 1000 || 'No data';
    }

    valueScalingFactor(data, i, interval_type) {
        let last = data.length - 1;
        if (i !== last) {
            // Complete time interval
            switch (interval_type) {
                case 'week':
                    return 24 * 7;
                case 'day':
                    return 24;
                default:
                    return 1;
            }
        } else {
            // Ongoing time interval; account for how far we are into it
            let currentDate = new Date();
            switch (interval_type) {
                case 'week': {
                    let difference = Math.abs(currentDate - new Date(data[last].date));
                    let days = difference / (1000 * 60 * 60 * 24);
                    return 24 * days;
                }
                case 'day': {
                    let difference = Math.abs(currentDate - new Date(data[last].date));
                    let hours = difference / (1000 * 60 * 60);
                    return hours;
                }
                default: {
                    let minutes = currentDate.getUTCMinutes();
                    return minutes / 60;
                }
            }
        }
    }

    loadData(interval_type) {
        this._updateChartData(interval_type);
        this.setState({ activeInterval: interval_type });
    }

    render() {
        let end = new Date();
        return (
            <>
                {this.state.facilities[0]?.retailer_facility_id && (
                    <div className="customer-number">
                        {text[this.context.language].savings.customer} #{this.context.jwt_content?.customer}
                    </div>
                )}
                <div className="SavingsWindow">
                    {this.state.activeRequests ? (
                        <Loader />
                    ) : (
                        <div className="SavingsWindow__container">
                            <div className="highliht-box">
                                {this.context.activeRequests === 0 && (
                                    <div className="intro-page">
                                        <h3 className="bold-text" style={{ color: this.context?.theme?.title_color || '#000000' }}>
                                            {text[this.context.language].navbar.savings}
                                        </h3>
                                        <InfoTooltip tooltip={text[this.context.language].tooltips.assuming} type="savings" />
                                    </div>
                                )}

                                <div className="boxes-row-wrapper">
                                    {this.state.devices.filter(d => d.product_type === 'ev').length !== 0 && (
                                        <div className="box box-main-layout box-main-layout--darken">
                                            <div className="icon icon--progress-bar">
                                                <ProgressBar
                                                    svgClass="circle-container savings__circle-container"
                                                    circleContainerBg="circle-bg savings__circle-bg "
                                                    circleContainerProgress="circle__progress savings__circle__progress"
                                                    statusProgressBar={100 - this.state.ev_percentage}
                                                    customProgressBarColors={{
                                                        progressBg: '#e6e6e6',
                                                        progressFilledBg: '#474E56',
                                                    }}
                                                />

                                                <div className="circle-info">
                                                    <img src="/assets/icons/electric-car.svg" alt="Savings Energy" className="ev-icon" />
                                                    <p>{this.state.ev_percentage}%</p>
                                                </div>
                                            </div>
                                            <div className="box__info fade-in">
                                                <div className="title">{text[this.context.language].savings.ev}</div>
                                                <p className="saving-calc">
                                                    {text[this.context.language].savings.totalSavings}
                                                    <span>
                                                        {this.state.ev_monthly_savings === undefined
                                                            ? 'No data'
                                                            : this.state.ev_monthly_savings.toFixed(0) + ' kr'}
                                                    </span>
                                                </p>
                                                <p className="saving-calc">
                                                    {text[this.context.language].savings.average}
                                                    <span>
                                                        {this.state.ev_monthly_cost === undefined
                                                            ? 'No data'
                                                            : this.state.ev_monthly_cost.toFixed(0) + ' kr'}
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                    )}

                                    {this.state.devices.filter(d => d.product_type === 'hvac').length !== 0 && (
                                        <div className="box box-main-layout box-main-layout--darken">
                                            <div className="icon icon--progress-bar">
                                                <ProgressBar
                                                    svgClass="circle-container savings__circle-container"
                                                    circleContainerBg="circle-bg savings__circle-bg"
                                                    circleContainerProgress="circle__progress savings__circle__progress"
                                                    statusProgressBar={100 - this.state.hvac_percentage}
                                                    customProgressBarColors={{
                                                        progressBg: '#e6e6e6',
                                                        progressFilledBg: '#474E56',
                                                    }}
                                                />
                                                <div className="circle-info">
                                                    <img src="/assets/icons/heating.svg" alt="Savings Energy" />
                                                    <p>{this.state.hvac_percentage}%</p>
                                                </div>
                                            </div>
                                            <div className="box__info fade-in">
                                                <div className="title">{text[this.context.language].savings.heating}</div>
                                                <p className="saving-calc">
                                                    {text[this.context.language].savings.totalSavings}
                                                    <span>
                                                        {this.state.hvac_monthly_savings === undefined
                                                            ? 'No data'
                                                            : this.state.hvac_monthly_savings.toFixed(0) + ' kr'}
                                                    </span>
                                                </p>
                                                <p className="saving-calc">
                                                    {text[this.context.language].savings.average}
                                                    <span>{this.state.hvac_monthly_cost.toFixed(0)} kr</span>
                                                </p>
                                            </div>
                                        </div>
                                    )}

                                    {this.state.devices.filter(d => d.product_type === 'cs').length !== 0 && (
                                        <div className="box box-main-layout box-main-layout--darken">
                                            <div className="icon icon--progress-bar">
                                                <ProgressBar
                                                    svgClass="circle-container savings__circle-container"
                                                    circleContainerBg="circle-bg savings__circle-bg "
                                                    circleContainerProgress="circle__progress savings__circle__progress"
                                                    statusProgressBar={100 - this.state.cs_percentage}
                                                    customProgressBarColors={{
                                                        progressBg: '#e6e6e6',
                                                        progressFilledBg: '#474E56',
                                                    }}
                                                />

                                                <div className="circle-info">
                                                    <img src="/assets/icons/electric-car.svg" alt="Savings Energy" className="ev-icon" />
                                                    <p>{this.state.cs_percentage}%</p>
                                                </div>
                                            </div>
                                            <div className="box__info fade-in">
                                                <div className="title">{text[this.context.language].savings.ev}</div>
                                                <p className="saving-calc">
                                                    {text[this.context.language].savings.totalSavings}
                                                    <span>
                                                        {this.state.cs_monthly_savings === undefined
                                                            ? 'No data'
                                                            : this.state.cs_monthly_savings.toFixed(0) + ' kr'}
                                                    </span>
                                                </p>
                                                <p className="saving-calc">
                                                    {text[this.context.language].savings.average}
                                                    <span>{this.state.cs_monthly_cost.toFixed(0)} kr</span>
                                                </p>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="boxes-row-wrapper boxed--energy-weather fade-in">
                                <div className="box box-main-layout">
                                    <div className="bold-text title">
                                        {this.state.spotprice} {this.state.spotprice_currency}/kWh
                                    </div>
                                    <div className="img">
                                        <img src={livePrice} alt="Live Price" />
                                        <div>{text[this.context.language].savings.livePrice}</div>
                                    </div>
                                </div>

                                <div className="box box-main-layout fade-in">
                                    <div className="bold-text title">{this.state.weather_temp} ℃</div>
                                    <div className="img">
                                        <img src={this.state.weather_icon || Sunny} alt={this.state.weather_description} />
                                        <div>{this.state.weather_description}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="SavingsChartCard fade-in">
                                <div className="SavingsChart__Row">
                                    <div className="graph-button-group">
                                        <Button
                                            text={text[this.context.language].savings.hourly}
                                            onClick={() => this.loadData('hour')}
                                            activeInterval={this.state.activeInterval}
                                        />
                                        <Button
                                            text={text[this.context.language].savings.daily}
                                            onClick={() => this.loadData('day')}
                                            activeInterval={this.state.activeInterval}
                                        />
                                        <Button
                                            text={text[this.context.language].savings.weekly}
                                            onClick={() => this.loadData('week')}
                                            activeInterval={this.state.activeInterval}
                                        />
                                    </div>
                                    {this.state.stackedConsumption.length > 1 && (
                                        <>
                                            <div className="current-time-indicator">
                                                <svg className="current-time-square">
                                                    <rect style={{ fill: '#009E73', strokeWidth: '0' }} />
                                                </svg>
                                                <p>{text[this.context.language].savings.current_time}</p>
                                            </div>
                                            <Chart
                                                chartType="ComboChart"
                                                loader={<div>Loading Chart</div>}
                                                data={this.state.stackedConsumption}
                                                height={'450px'}
                                                width={'100%'}
                                                options={{
                                                    interpolateNulls: true,
                                                    chartArea: {
                                                        top: '6%',
                                                        width: '76%',
                                                        height: '78%',
                                                        bottom: '10%',
                                                    },
                                                    seriesType: 'bars',
                                                    series: this.state.chartSeries,
                                                    isStacked: true,
                                                    backgroundColor: '#FFFFFF',
                                                    responsive: true,
                                                    legend: {
                                                        position: 'top',
                                                        maxLines: 2,
                                                        alignment: 'center',
                                                        textStyle: {
                                                            fontName: 'CalibriRegular',
                                                            fontSize: '12px',
                                                        },
                                                    },
                                                    lineWidth: 2,
                                                    animation: {
                                                        startup: true,
                                                        easing: 'inAndOut',
                                                        duration: 600,
                                                    },
                                                    hAxis: {
                                                        format:
                                                            this.state.activeInterval === 'week'
                                                                ? `'${text[this.context.language].savings.week}' ww`
                                                                : this.state.activeInterval === 'day'
                                                                ? 'dd MMM'
                                                                : 'dd MMM, HH:mm',
                                                    },
                                                    vAxis: {
                                                        viewWindow: {
                                                            min: this.state.consumptionZero && this.state.minValueGraph,
                                                            max: this.state.consumptionZero && this.state.maxValueGraph,
                                                        },
                                                    },
                                                    annotations: {
                                                        stem: {
                                                            color: '#009E73',
                                                        },
                                                        style: 'line',
                                                    },
                                                    vAxes: {
                                                        0: {
                                                            title: 'kWh',
                                                            textStyle: { color: '#000000' },
                                                            gridlines: { count: 1 },
                                                            baselineColor: '#e2f3e8',
                                                        },
                                                        1: {
                                                            title: `${this.state.currencyTitle} / kWh`,
                                                            textStyle: { color: '#000000' },
                                                            gridlines: { count: 1 },
                                                            baselineColor: '#e2f3e8',
                                                        },
                                                    },
                                                    bar: { groupWidth: '45%' },
                                                }}
                                                chartPackages={['corechart', 'controls']}
                                                controls={[
                                                    {
                                                        controlType: 'DateRangeFilter',
                                                        controlPosition: 'bottom',
                                                        controlWrapperParams: {
                                                            state: {
                                                                highValue: end,
                                                            },
                                                        },
                                                        options: {
                                                            filterColumnIndex: 0,
                                                            ui: {
                                                                cssClass: 'date-range',
                                                                showRangeValues: true,
                                                                step: 'hour',
                                                                format: {
                                                                    pattern:
                                                                        this.state.activeInterval === 'week'
                                                                            ? `'${text[this.context.language].savings.week}' ww`
                                                                            : this.state.activeInterval === 'day'
                                                                            ? 'dd MMMM'
                                                                            : 'dd MMM, HH:mm',
                                                                },
                                                            },
                                                        },
                                                    },
                                                ]}
                                            />
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </>
        );
    }

    savingsHistoryTime() {
        return text[this.context.language].savings.trackerActivated;
    }

    costHistoryTime() {
        return text[this.context.language].savings.cost + ` (${text[this.context.language].savings.trackerActivated})`;
    }
}

export default SavingsWindow;

import React, { useContext, useEffect } from 'react';
import Loader from '../Common/Loader';
import API from '../../api';
import AppContext from '../../Containers/app-context';
import { useLocation } from 'react-router';
import { getStorageItem, removeStorageItem } from '../../storage';

const Enode = () => {
    const context = useContext(AppContext);
    const location = useLocation();
    const error = location.search.includes('error');
    const onboardingActive = getStorageItem('onboarding-access-only');

    const selectedFacility = getStorageItem('selectedFacility');
    const facility = selectedFacility || context.facilities[0].facility_id;

    async function onboardEnodeDevice(title) {
        const api = new API(context.jwt_token);
        await api.onboard_enode_device(facility, title.toLowerCase()).then(
            _ => {
                onboardingActive === 'activated' ? context.onboardingRedirection(1) : (window.location.href = '/success');
            },
            _ => {
                onboardingActive === 'activated' ? context.onboardingRedirection(0) : (window.location.href = '/error');
            }
        );
    }

    useEffect(() => {
        if (getStorageItem('enode-device')) {
            if (error) {
                onboardingActive === 'activated' ? context.onboardingRedirection(0) : (window.location.href = '/error');
            } else {
                onboardEnodeDevice(getStorageItem('enode-device'));
            }

            removeStorageItem('enode-device');
        }
    }, []);

    return (
        <div>
            <Loader />
        </div>
    );
};

export default Enode;

import wallbox_icon from '../../assets/icons/wallbox.png';
import EmailBasedAuth from '../EmailBasedAuth/EmailBasedAuth';

class Wallbox extends EmailBasedAuth {
    constructor(props) {
        super(props, wallbox_icon, 'wallbox');
    }
}

export default Wallbox;

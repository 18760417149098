import React, { Component } from 'react';
import './Faq.css';

class Faq extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
        };
    }

    expantionHandler = () => {
        this.setState({ expanded: !this.state.expanded });
    };
    render() {
        return (
            <>
                <article className="Faq__question">
                    <header onClick={this.expantionHandler}>
                        <h4 className="Faq__questionTitle">{this.props.question.title}</h4>
                        <button className="Faq__questionBtn">{this.state.expanded ? <span>&#xf106;</span> : <span>&#xf107;</span>}</button>
                    </header>
                </article>
                <div className={this.state.expanded ? 'Faq__AnswerShow' : 'Faq__AnswerHidden'}>
                    {this.state.expanded && <p className="Faq__infoContainer">{this.props.question.info}</p>}
                </div>
            </>
        );
    }
}

export default Faq;

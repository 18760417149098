import React, { useContext, useState } from 'react';
import AppContext from '../../../Containers/app-context';
import Popup from 'reactjs-popup';
import text from '../../../assets/translation.json';
import './style.css';

const EditDeviceModal = ({ modalOpen, modalCloseHandler, currentName, editName, device }) => {
    const context = useContext(AppContext);
    const [updatedName, setUpdatedName] = useState(currentName);

    return (
        <div>
            <Popup className={'MyDevices__cancelpopup edit'} open={modalOpen} onClose={modalCloseHandler} closeOnDocumentClick={true}>
                <div className="edit-container">
                    <div className="popup-title">{text[context.language].myDevices.editName}</div>

                    <input
                        id="edit-name"
                        name="edit-name"
                        placeholder={currentName}
                        type="text"
                        className="edit-name-field"
                        value={updatedName}
                        onChange={e => setUpdatedName(e.target.value)}
                    />

                    <div className="actions">
                        <button onClick={modalCloseHandler} className="cancel-button">
                            {text[context.language].myDevices.cancel}
                        </button>
                        <button className="ok-button" onClick={() => editName(updatedName, device)}>
                            {text[context.language].myDevices.edit}
                        </button>
                    </div>
                </div>
            </Popup>
        </div>
    );
};

export default EditDeviceModal;

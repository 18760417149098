import React, { Component } from 'react';
import API from '../../../api';
import AppContext from '../../app-context';
import './DeviceBase.css';
import text from '../../../assets/translation.json';
import InfoTooltip from '../../../components/Common/InfoTooltip';

export default class DeviceBase extends Component {
    static contextType = AppContext;

    constructor(props) {
        super(props);

        let shouldSkip = false;
        if (this.props.device.spo.settings.skip_spo_until) {
            let today = new Date();
            today.setUTCHours(0, 0, 0, 0);
            let skipUntil = this.props.device.spo.settings.skip_spo_until;
            let dateSkipped = new Date(skipUntil).setUTCHours(0, 0, 0, 0);

            if (today.getTime() <= dateSkipped) {
                shouldSkip = true;
            }
        }

        this.state = {
            device: this.props.device,
            devices: this.props.devices,
            skipCharging: shouldSkip,
        };
    }

    componentDidMount() {
        this.api = new API(this.context.jwt_token);
    }

    inferState() {
        if (!this.state.device.connected) {
            return 'lost';
        }
        if (this.state.device.spo.unsupported) {
            return 'unsupported';
        }
        if (this.state.device.spo.pending) {
            return 'pending';
        }
        return 'active';
    }

    chargingHandler = async e => {
        let api = new API(this.context.jwt_token);
        let device = {
            ...this.props.device,
            spo: {
                ...this.props.device.spo,
                enabled: !this.props.device.spo.enabled,
            },
        };

        if (device.product_type === 'ev') {
            this.updateCSorEV('cs');
        } else if (device.product_type === 'cs') {
            this.updateCSorEV('ev');
        }

        this.setState({ device });
        this.props.updateDevice(device, device.product_type);
        await api.set_spot_price_optimized(device.id, device.spo.enabled);
    };

    updateCSorEV(type) {
        let arr = this.props.devices.filter(x => x.product_type === type);
        arr.forEach(async element => {
            if (element.spo.enabled) {
                let device = {
                    ...element,
                    spo: {
                        ...element.spo,
                        enabled: !element.spo.enabled,
                    },
                };

                this.props.updateDevice(device, device.product_type);
                await this.api.set_spot_price_optimized(device.id, device.spo.enabled);
            }
        });
    }

    renderSmartChargingSetting(name = text[this.context.language].myDevices.smart_charging) {
        let id = `SmartChargingToggle_${this.props.device.id}`;
        let type = `smart-charging-${this.props.device.product_type}`;
        return (
            <div className="DeviceBase__smartCharging" id={type}>
                <div className="DeviceBase__chargingTitle" style={{ fontFamily: this.context?.theme?.bold_font || 'CalibriRegular' }}>
                    {name}
                </div>
                <span className="DeviceBase__btnContainer">
                    <input
                        type="checkbox"
                        name={id}
                        id={id}
                        className="DeviceBaseSmartCharging"
                        onChange={this.chargingHandler}
                        checked={this.props.device.spo.enabled}
                    />
                    <label
                        className="DeviceBase__btnLabel"
                        style={this.props.device.spo.enabled ? { background: this.context?.theme?.button_color || '#ee801c' } : {}}
                        htmlFor={id}
                    ></label>
                </span>
            </div>
        );
    }

    skipCharging = async e => {
        let shouldSkip = !this.state.skipCharging;

        if (shouldSkip) {
            let date = new Date();
            let skip_until = date.toISOString().substring(0, 10);
            await this.api.set_device_settings(this.state.device.id, { skip_spo_until: skip_until });
        } else {
            await this.api.set_device_settings(this.state.device.id, { skip_spo_until: null });
        }

        this.setState({ skipCharging: !this.state.skipCharging });
    };

    renderSkipCharging() {
        return (
            <div className="Ev__secondRowFirst skip-charging">
                <div className="Ev__secondRowTitle">
                    {text[this.context.language].myDevices.skip_charge}
                    <InfoTooltip tooltip={text[this.context.language].tooltips.skip_charging} type="skip-charge" />
                </div>
                <div className="DeviceBase__smartCharging">
                    <span className="DeviceBase__btnContainer">
                        <input
                            type="checkbox"
                            name={this.state.device.id}
                            id={this.state.device.id}
                            className="DeviceBaseSmartCharging"
                            onChange={e => this.skipCharging(e)}
                            checked={this.state.skipCharging}
                        />
                        <label
                            className="DeviceBase__btnLabel"
                            style={this.state.skipCharging ? { background: this.context?.theme?.button_color || '#ee801c' } : {}}
                            htmlFor={this.state.device.id}
                        ></label>
                    </span>
                </div>
            </div>
        );
    }
}

import mitsubishi_icon from '../../assets/icons/mitsubishi.svg';
import EmailBasedAuth from '../EmailBasedAuth/EmailBasedAuth';

class Mitsubishi extends EmailBasedAuth {
    constructor(props) {
        super(props, mitsubishi_icon, 'mitsubishi');
    }
}

export default Mitsubishi;

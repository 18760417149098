import { ToastContainer, toast, Flip } from 'react-toastify';
import React, { Component } from 'react';
import { Oval } from 'react-loader-spinner';
import API from '../../api';
import AppContext from '../../Containers/app-context';
import './EmailBasedAuth.css';
import text from '../../assets/translation.json';
import cc from 'classcat';
import { getStorageItem } from '../../storage';

const toast_settings = {
    position: 'top-center',
    autoClose: 500,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    transition: Flip,
    closeButton: false,
};

export default class EmailBasedAuth extends Component {
    static contextType = AppContext;

    constructor(props, icon, manufacturer) {
        super(props);
        this.icon = icon;
        this.manufacturer = manufacturer;
        this.state = {
            posted: false,
            loader: false,
            success: false,
            email: '',
            password: '',
            consent: false,
            code: '',
            passwordType: 'password',
            authType: 'password',
        };
    }

    setAuthenticationType = () => {
        if (this.manufacturer.toLowerCase() === 'own_sensibo' || this.manufacturer.toLowerCase() === 'mer') {
            this.setState({ authType: 'token' });
        }
    };

    componentDidMount() {
        this.setAuthenticationType();
    }

    handleEmailChange = event => {
        this.setState({ email: event.target.value });
    };

    handlePasswordChange = event => {
        this.setState({ password: event.target.value });
    };

    handleCodeChange = event => {
        this.setState({ code: event.target.value });
    };

    togglePasswordInputType = () => {
        let passwordType = this.state.passwordType === 'password' ? 'text' : 'password';
        this.setState({ passwordType });
    };

    validate = () => {
        if (this.state.authType === 'password') {
            if (!this.state.email) {
                toast.error(text[this.context.language].addNewDevices.enter_email, toast_settings);
                return false;
            } else if (!this.state.password) {
                toast.error(text[this.context.language].addNewDevices.enter_password, toast_settings);
                return false;
            }
        } else if (this.state.authType === 'token' && !this.state.code) {
            toast.error(text[this.context.language].addNewDevices.enter_code, toast_settings);
            return false;
        }

        return true;
    };

    submit = () => {
        const api = new API(this.context.jwt_token);
        const validation = this.validate();

        if (validation) {
            this.setState({ loader: true });
            const config = {
                headers: {
                    'content-type': 'application/json',
                },
            };

            const auth = this.state.code
                ? this.state.code
                : {
                      email: this.state.email,
                      password: this.state.password,
                  };

            const selectedFacility = getStorageItem('selectedFacility');
            const facility = selectedFacility || this.context.facilities[0].facility_id;
            api.client
                .post(
                    `/onboarding/emulate/${facility}/devices`,
                    JSON.stringify({
                        auth,
                        manufacturer: this.manufacturer,
                    }),
                    config
                )
                .then(res => {
                    this.setState({
                        posted: true,
                        success: true,
                        loader: false,
                    });

                    if (getStorageItem('onboarding-access-only') === 'activated' || window.location.pathname.startsWith('/onboarding')) {
                        this.context.onboardingRedirection(1);
                    } else {
                        window.location.href = '/success';
                    }
                })
                .catch(err => {
                    if (getStorageItem('onboarding-access-only') === 'activated' || window.location.pathname.startsWith('/onboarding')) {
                        this.context.onboardingRedirection(0);
                    } else {
                        if (err.response) {
                            toast.error(text[this.context.language].addNewDevices.error, toast_settings);
                        }

                        this.setState({
                            success: false,
                            posted: true,
                            loader: false,
                        });
                    }
                });
        }
    };

    render() {
        return (
            <section className="content">
                <div className="container-fluid">
                    <ToastContainer />
                    <div className="row" style={{ marginTop: '12px' }}>
                        <div className="col-md-12">
                            <img
                                className="img-styling center"
                                src={this.icon}
                                alt={`${this.manufacturer} logo`}
                                style={{
                                    textAlign: 'center',
                                }}
                            />

                            <div className="card card-primary">
                                {this.state.authType === 'token' ? (
                                    <div className="card-body">
                                        <div className="form-group">
                                            <label style={{ margin: '0 0 5px 10px', fontWeight: '600' }}>
                                                {text[this.context.language].addNewDevices.verification_code}*
                                            </label>
                                            <input
                                                id="code"
                                                type="text"
                                                value={this.state.code}
                                                onChange={this.handleCodeChange}
                                                className="form-control"
                                                placeholder={text[this.context.language].addNewDevices.verification_code}
                                                required
                                            />
                                        </div>
                                        <div className="form-group" style={{ marginTop: '20px' }}>
                                            <input type="checkbox" onChange={() => this.setState({ consent: !this.state.consent })} />
                                            <label style={{ marginLeft: '10px' }} htmlFor="consent">
                                                {text[this.context.language].addNewDevices.approve_data}
                                            </label>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="card-body">
                                        <div className="form-group">
                                            <label>{text[this.context.language].addNewDevices.email}*</label>
                                            <input
                                                type="email"
                                                value={this.state.email}
                                                onChange={this.handleEmailChange}
                                                className="form-control"
                                                placeholder={text[this.context.language].addNewDevices.email}
                                            />
                                        </div>
                                        <div className="form-group" style={{ marginTop: '20px' }}>
                                            <label>{text[this.context.language].addNewDevices.password}*</label>

                                            <div className="password">
                                                <input
                                                    type={this.state.passwordType}
                                                    name="password"
                                                    value={this.state.password}
                                                    onChange={this.handlePasswordChange}
                                                    className="form-control"
                                                    placeholder={text[this.context.language].addNewDevices.password}
                                                />
                                                <button className="password-viewer" onClick={this.togglePasswordInputType}>
                                                    {this.state.passwordType === 'password' ? (
                                                        <i className={cc([`fa fa-eye-slash black`])}></i>
                                                    ) : (
                                                        <i className={cc([`fa fa-eye black`])}></i>
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                        <div className="form-group checkbox-wrapper" style={{ marginTop: '20px' }}>
                                            <input type="checkbox" onChange={() => this.setState({ consent: !this.state.consent })} />
                                            <label style={{ marginLeft: '10px' }} htmlFor="consent">
                                                {text[this.context.language].addNewDevices.approve_data}
                                            </label>
                                        </div>
                                    </div>
                                )}
                                <div className="card-footer">
                                    {this.state.loader === true && (
                                        <div className="">
                                            <Oval type="Puff" color="#f67e00" height={30} width={30} />
                                        </div>
                                    )}
                                    {this.state.loader === false && (
                                        <button
                                            type="submit"
                                            onClick={this.submit}
                                            className="btn"
                                            disabled={!this.state.consent}
                                            style={{
                                                color: this.context?.theme?.button_text_color || '#000',
                                                backgroundColor: this.context?.theme?.button_color || '#b9b7b7',
                                            }}
                                        >
                                            {text[this.context.language].addNewDevices.login}
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

import React from 'react';

const NavbarItem = ({ imgSrc, text, className }) => {
    return (
        <div className={className}>
            <img src={imgSrc} alt="my devices logo" className="mobileNavImg" />
            {text && <span>{text}</span>}
        </div>
    );
};

export default NavbarItem;

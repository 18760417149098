import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import SavingsWindow from './SavingWindow/SavingsWindow';
import AppContext from '../app-context';

import savingshot from '../../assets/icons/savingshot.png';
import savingarrow from '../../assets/icons/savingarrow.png';
import './Savings.css';
import Loader from '../../components/Common/Loader';
import text from '../../assets/translation.json';
import loadDevices from '../MyDevices/loadDevices';
import { getStorageItem, removeStorageItem } from '../../storage';

class Savings extends Component {
    static contextType = AppContext;
    constructor(props) {
        super(props);
        this.state = {
            noOfDevices: 0,
            isSPOEnabled: true,
        };
    }

    async componentDidMount() {
        if (getStorageItem('devices_status')) {
            this.setState({ noOfDevices: parseInt(getStorageItem('devices_status')) });
        }

        if (
            window.location.search.includes('code') &&
            window.location.search.includes('state') &&
            getStorageItem('smartcar-onboarding') === 'activated'
        ) {
            const query = new URLSearchParams(window.location.search);
            const code = query.get('code');
            const error = query.get('error');
            const state = query.get('state');
            this.context.onComplete(code, error, state);
            removeStorageItem('smartcar-onboarding');
        }

        let devices = await loadDevices(this.context);
        let enabledSPO = devices.filter(x => x.spo.enabled === true);
        if (enabledSPO.length === 0) {
            this.setState({ isSPOEnabled: false });
        }

        this.setState({
            jwt_content: this.context.jwt_content,
            jwt_token: this.context.jwt_token,
        });
    }

    render() {
        return (
            <>
                {getStorageItem('onboarding-access-only') === 'activated' ? (
                    <Loader />
                ) : (
                    <>
                        {!this.state.isSPOEnabled &&
                            this.state.noOfDevices > 0 &&
                            (window.location.pathname === '/savings' || window.location.pathname === '/') && (
                                <div className="gray-overlay">
                                    <span>{text[this.context.language].savings.disabled_spo}</span>
                                </div>
                            )}
                        {this.state.noOfDevices === 0 && (
                            <div className="Savings">
                                <div className="Savings__container">
                                    {/* <h3 className="bold-text" style={{ color: this.context?.theme?.title_color || '#000000' }}>{text[this.context.language].myDevices.addDev}</h3> */}
                                    <section className="Saving__imageSection">
                                        <div className="Saving__imageContainer">
                                            <img src={savingarrow} alt="add device arrow" className="Savings__arrowImage" />

                                            <img src={savingshot} alt="add device logo" className="Savings__shotImage" />
                                        </div>
                                        <button className="Savings__AddBtn" style={{ background: this.context.theme?.button_color || '#ee801c' }}>
                                            <NavLink exact to="/adddevices">
                                                &#x2b;
                                            </NavLink>
                                        </button>
                                    </section>
                                    <section className="Savings__newDevice">
                                        <p>{text[this.context.language].myDevices.addDevicesStart}</p>
                                        <NavLink exact to="/adddevices">
                                            <button
                                                className="Saving__addtextbtn bold-text"
                                                style={{
                                                    color: this.context?.theme?.button_text_color || '#ffffff',
                                                    backgroundColor: this.context?.theme?.button_color || '#ee801c',
                                                }}
                                            >
                                                {text[this.context.language].myDevices.addDev}
                                            </button>
                                        </NavLink>
                                    </section>
                                </div>
                            </div>
                        )}
                        {this.state.noOfDevices > 0 && <SavingsWindow />}
                    </>
                )}
            </>
        );
    }
}

export default Savings;

import React from 'react';
import './style.css';

const Loader = _ => {
    return (
        <div className="wrapper">
            <div className="emulate-loader">
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
};

export default Loader;

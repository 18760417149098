import React, { useContext } from 'react';
import text from '../../../assets/translation.json';
import Popup from 'reactjs-popup';
import AppContext from '../../../Containers/app-context';
import './style.css';

const DeleteDeviceModal = ({ modalOpen, modalCloseHandler, deleteDevice, device }) => {
    const context = useContext(AppContext);

    return (
        <div>
            <Popup className={'custom-popup'} open={modalOpen} onClose={modalCloseHandler} closeOnDocumentClick={true}>
                <div className="cancel-container">
                    <div className="popup-title">{text[context.language].myDevices.remove_device}</div>

                    <div className="actions">
                        <button onClick={e => modalCloseHandler(e)} className="cancel-button">
                            {text[context.language].myDevices.cancel}
                        </button>
                        <button onClick={() => deleteDevice(device)} className="ok-button">
                            {text[context.language].myDevices.delete}
                        </button>
                    </div>
                </div>
            </Popup>
        </div>
    );
};

export default DeleteDeviceModal;

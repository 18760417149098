import React, { Component } from 'react';
import './AddDevices.css';

class DevicesCatagory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clicked: false,
            imgSrc: '',
        };
    }
    clickHandler = () => {
        this.setState({ clicked: !this.state.clicked });
    };
    render() {
        return (
            <>
                <div className={`Devices__item ${this.props.vehicle.label.toLowerCase()}`} onClick={this.props.onClick}>
                    <div className="Devices__itemContent">
                        <div>
                            <img className="Devices__itemImg" src={this.props.vehicle.logo_url} alt={`${this.props.vehicle.label} Logo`} />
                            <h1>{this.props.vehicle.label}</h1>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default DevicesCatagory;

import { ToastContainer, toast, Flip } from 'react-toastify';
import React, { Component } from 'react';
import API from '../../api';
import AppContext from '../../Containers/app-context';
import { Redirect } from 'react-router-dom';
import Loader from '../Common/Loader';
import text from '../../assets/translation.json';

const toast_settings = {
    position: 'top-center',
    autoClose: 700,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    transition: Flip,
    closeButton: false,
    onClose: () => (window.location.href = '/adddevices'),
};

export default class TokenBasedAuth extends Component {
    static contextType = AppContext;

    constructor(props, manufacturer, product) {
        super(props);
        this.manufacturer = manufacturer;
        this.state = {
            success: false,
            product,
        };
    }

    componentDidMount() {
        const api = new API(this.context.jwt_token);
        const url = window.location.href.split('?');
        if (url.length > 1) {
            const aggregator = this.context.enabledManufacturers.find(x => x.id === this.manufacturer)?.aggregator;

            if (!aggregator) {
                toast.error(`${text[this.context.language].addNewDevices.error_occured}`, toast_settings);
                return;
            }

            const urlParams = new URLSearchParams(window.location.search);
            const code = urlParams.get('code');
            const config = {
                headers: {
                    'content-type': 'application/json',
                },
            };

            let body = {
                auth: code,
                manufacturer: this.manufacturer,
                simulated_enode_device: false,
            };

            if (this.state.product) {
                body.product = this.state.product;
            }

            api.client
                .post(`/onboarding/${aggregator}/${this.context.facilities[0].facility_id}/devices`, JSON.stringify(body), config)
                .then(_res => {
                    this.setState({
                        success: true,
                    });
                })
                .catch(err => {
                    if (err.response) {
                        toast.error(`${text[this.context.language].addNewDevices.error_occured}`, toast_settings);
                    }
                });
        }
    }

    render() {
        if (this.state.success) {
            return <Redirect to="/mydevices" />;
        }
        return (
            <>
                <ToastContainer />
                <div className="container">
                    <Loader />
                </div>
            </>
        );
    }
}

import React from 'react';
import './style.css';
import text from '../../assets/translation.json';
import { useContext } from 'react';
import AppContext from '../../Containers/app-context';

const TeslaSuccessPage = () => {
    const context = useContext(AppContext);

    return (
        <div className="notification-wrapper">
            <div className="notification">
                <img className="image" src="/assets/icons/logos/tesla.png" alt="Tesla Logo" />
                <div className="button-wrap">
                    <h4>{text[context.language].addNewDevices.tesla.success_onboarding_title}</h4>
                    <p>{text[context.language].addNewDevices.tesla.success_onboarding_text}</p>
                    <a
                        href="https://www.tesla.com/ownersmanual/modely/en_us/GUID-E004FAB7-1C71-448F-9492-CACF301304D2.html"
                        target="_blank"
                        rel="noreferrer"
                    >
                        {text[context.language].addNewDevices.tesla.phone_as_key}
                    </a>
                    <a href="https://www.tesla.com/_ak/tesla.emulate.energy" target="_blank" rel="noreferrer">
                        {text[context.language].addNewDevices.tesla.link_virtual_key}
                    </a>
                </div>
            </div>
        </div>
    );
};

export default TeslaSuccessPage;

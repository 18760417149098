import React from 'react';
import AppContext from '../../app-context';
import DeviceBase from './DeviceBase';
import cc from 'classcat';

import 'reactjs-popup/dist/index.css';
import './Hvac.css';
import text from '../../../assets/translation.json';
import API from '../../../api';
import InfoTooltip from '../../../components/Common/InfoTooltip';
import { ChargeScheduleMessage } from './ChargeScheduleMessage';

class Hvac extends DeviceBase {
    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            heatCurveOffset: 0,
        };
    }

    async componentDidMount() {
        let api = new API(this.context.jwt_token);
        let offset = (await api.heat_curve_offset(this.state.device.id)).data;
        this.setState({ heatCurveOffset: offset?.heat_curve_offset || 0 });
    }

    async changeOffset(plus) {
        let offset = 0;
        if (this.state.heatCurveOffset < 10 && this.state.heatCurveOffset >= -10 && plus) {
            offset = this.state.heatCurveOffset + 1;
            this.setOffset(offset);
        } else if (this.state.heatCurveOffset <= 10 && this.state.heatCurveOffset > -10 && !plus) {
            offset = this.state.heatCurveOffset - 1;
            this.setOffset(offset);
        }
    }

    async setOffset(offset) {
        let api = new API(this.context.jwt_token);
        this.setState({ heatCurveOffset: offset });
        await api.set_heat_curve_offset(this.state.device.id, offset);
    }

    render() {
        const deviceState = this.inferState();
        const lostStatus = this.props.lostStatus;

        let tooltip =
            deviceState === 'unsupported' && this.state.device.spo.unsupported_reason !== null
                ? this.state.device.spo.unsupported_reason
                : this.props.tooltipMsgs[deviceState];

        return (
            <div className={cc(['Hvac', deviceState])}>
                {(deviceState === 'pending' || deviceState === 'lost' || deviceState === 'unsupported') && (
                    <div className={cc(['badge-indicator', deviceState])}>
                        <span className="dot"></span>
                        {text[this.context.language].myDevices[deviceState]}
                        <InfoTooltip tooltip={tooltip} type={`deviceStatus-${deviceState}`} />
                    </div>
                )}

                <div
                    className="Hvac__crossCard"
                    onClick={() => {
                        this.props.openDeleteModal(this.state.device);
                    }}
                >
                    &#xf00d;
                </div>

                <div className={cc(['Hvac__firstRow', { [`device-state-lost`]: lostStatus }])}>
                    <div className="Hvac__titleContainer">
                        <div className="Hvac__title bold-text">{this.props.productName(this.state.device)}</div>
                        <div
                            className="edit-icon"
                            onClick={() => {
                                this.props.openEditModal(this.state.device);
                            }}
                        >
                            &#x1F58A;
                        </div>
                    </div>

                    {lostStatus ? (
                        <div className="restore-btn">
                            <button
                                className="btn"
                                style={{
                                    backgroundColor: this.context?.theme?.button_color || '#aeaeae',
                                    color: this.context?.theme?.button_text_color || '#000000',
                                }}
                                onClick={() => this.context.restoreAccess(this.state.device)}
                            >
                                {text[this.context.language].myDevices.restore_access}
                            </button>
                        </div>
                    ) : (
                        this.renderSmartChargingSetting(text[this.context.language].myDevices.smart_heating)
                    )}
                </div>

                {!lostStatus && (
                    <>
                        <ChargeScheduleMessage latestAction={this.state.device.latestAction} className="hvac-schedule" />
                        <div className="third-row">
                            <div className="Hvac__titleContainer">
                                <div className="third-row-title">
                                    {text[this.context.language].myDevices.heatCurve}
                                    <InfoTooltip tooltip={text[this.context.language].tooltips.curveOffset} type="hvac" />
                                </div>
                            </div>
                            <div className="Hvac__heatCurve">
                                <div className="Hvac__Seprator third-row-title">{this.state.heatCurveOffset}</div>
                            </div>
                        </div>
                        <div className="Hvac__secondRow">
                            <div className="Hvac__Temperature">
                                <div className="Hvac__TemperatureC">
                                    {this.state.device.measurements.indoor_temperature ? (
                                        <span>
                                            {this.state.device.measurements.indoor_temperature} <span className="Hvac__Celsius">&#8451;</span>
                                        </span>
                                    ) : (
                                        text[this.context.language].myDevices.sensor_missing
                                    )}
                                </div>
                                <div className="Hvac__subTitles">{text[this.context.language].myDevices.temperature}</div>
                            </div>
                        </div>
                    </>
                )}

                {/* <div className="Hvac__HotWater">
                        <div className="Hvac__HotWaterBtnContainer">
                            <button className="Hvac__HotWaterBtn">
                                <img
                                    src={HotWaterBoost}
                                    alt="Hot Water Boost"
                                    className="Hvac__BoostImg"
                                ></img>
                            </button>
                        </div>
                        <div className="Hvac__subTitles">Hot Water Boost</div>
                    </div> */}
            </div>
        );
    }
}

export default Hvac;

import React from 'react';
import '../InfoTooltip/InfoTooltip.css';
import faqIcon from '../../../assets/icons/info.png';
import cc from 'classcat';

const InfoTooltip = props => {
    let className = `tooltip_${props.type}`;
    return (
        <div className="info-wrapper">
            <div className="info-icon">
                <img src={faqIcon} alt="" />
                <span className={cc(['tooltip', className])}>{props.tooltip}</span>
            </div>
        </div>
    );
};

export default InfoTooltip;

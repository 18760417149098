import React from 'react';
import './style.css';

const ProgressBar = ({ svgClass, circleContainerBg, circleContainerProgress, statusProgressBar, customProgressBarColors }) => {
    return (
        <div>
            <svg className={svgClass} viewBox="2 -2 28 36" xmlns="http://www.w3.org/2000/svg">
                <circle className={circleContainerBg} style={{ stroke: customProgressBarColors.progressBg }} r="16" cx="16" cy="16"></circle>
                <circle
                    className={circleContainerProgress}
                    style={{
                        strokeDashoffset: statusProgressBar,
                        stroke: customProgressBarColors.progressFilledBg,
                    }}
                    r="16"
                    cx="16"
                    cy="16"
                ></circle>
            </svg>
        </div>
    );
};

export default ProgressBar;

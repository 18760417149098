import React from 'react';
import { useContext } from 'react';
import { useLocation } from 'react-router';
import AppContext from '../../Containers/app-context';
import { useEffect } from 'react';
import '../Onboarding/Onboarding.css';
import { useState } from 'react';
import Loader from '../Common/Loader';
import PopUp from '../modal/AuthModal/modal';
import { reportError } from '../../report';
import { getStorageItem, removeStorageItem, setStorageItem } from '../../storage';

export default function OnboardingAccess() {
    const location = useLocation();
    const context = useContext(AppContext);
    const [modalType, setModalType] = useState();
    const [modalShow, setModalShow] = useState(false);

    async function onboardDevice() {
        const params = new URLSearchParams(location.search);
        const callback = params.get('callback');
        await setStorageItem('onboarding-access-only', 'activated');
        await removeStorageItem('onboarding-url');

        const selectedFacility = params.get('facility');
        if (selectedFacility) {
            await setStorageItem('selectedFacility', selectedFacility);
        }

        if (callback) {
            await setStorageItem('onboarding-url', callback);
        }

        let manufacturer = location.pathname.split('/')[2];
        let findManufacturer = context.enabledManufacturers.find(x => x.id === manufacturer);
        if (!findManufacturer) {
            await reportError(`Failed to resolve manufacturer ${manufacturer}`);
        }

        if (findManufacturer.aggregator === 'emulate' && findManufacturer.id !== 'own_tesla' && findManufacturer.id !== 'viessmann') {
            setModalType(findManufacturer.id);
            setModalShow(true);
            context.startMetric(findManufacturer.id);
        } else {
            context.addDevicesHandler(findManufacturer);
        }
    }

    useEffect(() => {
        onboardDevice();
    }, []);

    const hideModal = () => {
        let callback = getStorageItem('onboarding-url');

        if (callback) {
            callback.includes('?') ? window.open(callback + '&success=0', '_self') : window.open(callback + '?success=0', '_self');
        } else {
            window.close();
        }

        removeStorageItem('onboarding-url');
        removeStorageItem('onboarding-access-only');
    };

    return (
        <>
            {modalType && <PopUp type={modalType} show={modalShow} onHide={hideModal} />}
            <div className="img-wrapper">
                <Loader />
            </div>
        </>
    );
}

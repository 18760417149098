import axios from 'axios';
import { reportMetric } from './report';

export function getCookie(name) {
    for (let part of document.cookie.split(';')) {
        while (part.charAt(0) === ' ') {
            part = part.substring(1, part.length);
        }
        if (part.indexOf(`${name}=`) === 0) {
            return part.substring(name.length + 1, part.length);
        }
    }
    return null;
}

export async function storeJWTToCookie(jwt) {
    try {
        await axios.get(window.location.origin + '/session/token', {
            headers: {
                'emulate-api-token': jwt,
            },
        });
    } catch (error) {
        reportMetric('Failed to store JWT to cookie', 'app.jwt-cookie-storage-error', {
            error: JSON.stringify(error),
            useragent: navigator.userAgent,
            referrer: document.referrer,
            url: window.location.href,
        });
    }
}

export async function storeStateToCookie(serializedState) {
    try {
        await axios.get(window.location.origin + '/session/state', {
            headers: {
                'emulate-state': serializedState,
            },
        });
    } catch (error) {
        reportMetric('Failed to store state to cookie', 'app.state-cookie-storage-error', {
            error: JSON.stringify(error),
            useragent: navigator.userAgent,
            referrer: document.referrer,
            url: window.location.href,
        });
    }
}
